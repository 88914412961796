<div
    *ngIf="percentage === undefined"
    class="info-marker"
    [ngClass]="quality">
    <div class="dot"></div>
    <ng-content></ng-content>
</div>
<div
    *ngIf="percentage !== undefined && !invertQuality"
    class="info-marker"
    [ngClass]="{best: percentage > 0, neutral: percentage === 0, poor: percentage < 0}">
    <div class="percentage">
        {{ (percentage >= 0 ? percentage : -percentage) | number : '1.0-0'}}%
    </div>
    <div class="arrow"></div>
</div>
<!-- TODO: remove this when backend is changed to return quality for reduction -->
<div
    *ngIf="percentage !== undefined && invertQuality"
    class="info-marker"
    [ngClass]="{best: percentage < 0, neutral: percentage === 0, poor: percentage > 0, inverted: invertQuality}">
    <div class="percentage">
        {{ (percentage >= 0 ? percentage : -percentage) | number : '1.0-0'}}%
    </div>
    <div class="arrow"></div>
</div>
