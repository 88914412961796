import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { PropertyBoostrapper } from './view/property/bootstrapper.service';

import { AppInjector } from './app.module';
import { PaymentGuard } from './core/guards/payment.guard';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [PaymentGuard],
        children: [
            {
                path: 'properties',
                loadChildren: async () => {
                    const service = AppInjector.get(PropertyBoostrapper);
                    const leedId = parseInt(window.location.pathname.split('/')[2]);

                    service.fetchPrice();
                    if (!isNaN(leedId)) {
                        await service.getProjectTeam(leedId).toPromise();
                    }

                    const m = await import('./view/property/property.module');

                    return m.PropertyModule;
                },
                data: {
                    breadcrumb: 'Properties'
                }
            },
            {
                path: 'user',
                loadChildren: () => import('./view/user/user.module').then(
                    (m) => m.UserModule
                ),
                data: {
                    breadcrumb: 'Home'
                }
            },
        ],
    },
    {
        path: 'plaque',
        loadChildren: () => import('./view/project/project.module').then(
            (m) => m.ProjectModule
        ),
    },
    {
        path: 'arc-essentials',
        redirectTo: 'user/purchase-subscription',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
