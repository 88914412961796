import { environment } from 'src/environments/environment';

import 'moment-timezone';
import * as moment from 'moment';

export class Config {
    public static headers: any;

    public static arc_pro_label: string = 'Arc Essentials';
    public static arc_gsa_label: string = 'Play to Zero';
    public static app_loader: boolean = false;
    public static jwt_loader: boolean = false;
    public static stream_upload: boolean = false;
    public static show_refresh: boolean = false;
    public static isAuthenticated: boolean = false;
    public static search_leed_id: number;
    public static auth_fetched: number = 0;
    public static search_data: any;
    public static selectedAgreementRow: string | undefined;

    public static sso_client: string = environment.sso_client;
    public static auth0_url: string = environment.auth0_url;
    public static auth0_domain: string = environment.auth0_domain;
    public static accounts_page: string = environment.accounts_page;

    public static ENV_FLAG: string = environment.ENV_FLAG;
    public static basic_api_url: string = environment.basic_api_url;
    public static sub_key: string = environment.subscription_key;
    public static logout_url = environment.logout_url;
    public static logout_url_jp = environment.logout_url;
    public static LO_URL = environment.LO_URL;
    public static LOV2_URL = environment.LOV2_URL;
    public static animation_url = environment.animation_url;
    public static FRONTEND_BASE_URL = environment.FRONTEND_BASE_URL;
    public static DEMO_FLAG = environment.DEMO_FLAG;
    public static LO_PROXY_URL = environment.LO_PROXY_URL;
    public static landingPageURL = environment.landingPageURL;
    public static usgbc_basic_url = environment.usgbc_basic_url;
    public static insight_url = environment.insight_url;
    public static v1_applicable_date = environment.v1_applicable_date;
    public static it_subscription_key = environment.it_subscription_key;
    public static all_arc_material = environment.all_arc_material;
    public static s3_bucket_url = environment.s3_bucket_url;
    public static HUB_URL = environment.HUB_URL;

    public static show_paynow_mat = [4619, 4621, 4640, 4641, 4642, 4644, 4646, 5671, 5672, 5673, 5674, 3115, 5676, 5677, 5678, 5679, 5680, 5681, 3122, 5683, 5684, 3480, 3140, 3141, 3142, 3143, 3144, 3147, 3148, 3149, 3152, 3153, 3154, 3158, 3159, 3160, 3161, 3162, 3163, 3200, 4752, 4245, 4248, 4249, 4253, 4275, 4276, 4277, 4278, 4281, 4282, 4285, 4286, 4287, 4288, 4289, 4291, 4292, 4293, 4294, 4295, 4296, 5352, 5353, 5354, 5355, 5161, 3114, 5374, 5375, 5675, 5388, 5389, 5404, 5405, 5406, 5407, 5408, 5409, 5412, 5413, 4647, 3370, 5682, 4953, 4954, 3420, 4957, 4958, 4959, 4962, 4983, 4984, 3476, 4989, 3477, 4994, 4995, 3478, 6024, 6025, 3479, 5004, 5005, 5006, 5007, 5009, 5010, 5011, 5012, 5013, 5014, 5015, 5016, 3483, 3495, 3496, 3021, 6110, 6112, 6113, 6114, 6115, 6116, 6118, 6119];
    public static rating_systems = {
        building: 'LEED V4 O+M: EB WP',
        city: 'LEED-CT',
        community: 'LEED-CM',
        district: 'LEED-DT',
        neighborhood: 'LEED-NB',
        transit: 'LEED V4 O+M: TR',
        parksmart: 'PARKSMART',
        sites: 'SITES'
    };

    public static banner_message = '';
    public static need_banner = '';

    public static need_review_tab: boolean = true;
    public static cert_loader: boolean = false;

    public static currency: string;
    public static EUserRole: string;
    public static access: string;
    public static portfolio_access: string = 'R'; ;
    public static redirect_to_landing_page_text: string;
    public static CreditShortId: string;

    public static price: any = {};
    public static countries: any = null;

    public static range_end: any;
    public static last_certified_date: any;
    public static range_start: any;
    public static range_month: any;
    public static max_range: any = moment(new Date()).add(1, 'year').month('Dec').date(31);
    public static weekdays = moment.weekdaysMin();
    public static previous_range: any;
    public static previous_range_start: any;
    public static previous_range_end: any;
    public static show_last_certified: boolean;
    public static loading_insights: boolean;
    public static maintenance_banner: boolean = false;
    public static score_type: string;
    public static state_params: any = null;
    public static calendar_matrix: any = [];
    public static uploading: any = [];
    public static uploaded_files: any = [];
    public static uploaded_files_energy: any = [];
    public static uploaded_files_water: any = [];
    public static uploaded_files_waste: any = [];
    public static uploaded_files_tranport: any = [];
    public static uploaded_files_human: any = [];
    public static uploaded_files_excel: any = [];
    public static uploaded_files_add: any = [];
    public static uploaded_files_form: any = [];
    public static searchResults_leed_id: any = [];
    public static searchResults_pf_id: any = [];
    public static upload_category: any;
    public static form_type: any;
    public static init_elemScoreVersion: any;
    public static currentUpload: boolean = false;
    public static starred: boolean = false;
    public static hide_sidebar: boolean = false;
    public static currentUploadName: string = '';
    public static default_page: string = '';

    public static token_incorrect_modal_text: any;
    public static score_notification_reminder: any;
    public static extension_approval_till: any;
    public static extension_request_text = 'Request Extension';
    public static extension_request_flag = false;
    public static static_credit: boolean = false;
    public static policy_check: boolean = false;
    public static policy_loader: boolean = false;
    public static file_loader: boolean = false;
    public static file_check: boolean = false;
    public static resource_credits: boolean = false;
    public static show_score_help: boolean = false;
    public static score_code_other_upgrade: any = { text: '' };
    public static scoreNotificationCheckStatus: any = { accept: false };
    public static current_score_version_detail: any = {};
    public static extension_request_exist: any = {};
    public static new_score_version_detail: any = {};
    public static new_version_change_log: any = [];
    public static current_version_change_log: any = [];
    public static user_score_version_update_notification: any = [];
    public static notification_version_change_log: any = [];
    public static old_version_scores: any = {};
    public static new_version_scores: any = {};
    public static score_scenario: any = {};
    public static not_now_dropdown: any = { tomorrow: 'Remind me tomorrow', next_week: 'Remind me in one week', next_month: 'Remind me in one month' };
    public static score_version_confirmation_flag = false;
    public static score_version_confirmation_text = 'Update my score';
    public static score_comparison_received = false;
    public static score_version_notification: any = [];
    public static arc_hide_all_content = false;
    public static version_comparison_show_modal = false;
    public static score_version_extension_request: any = [];
    public static maintenance_apps: any = ['billing', 'agreement', 'review-selection', 'review-payment', 'review-selection', 'actions-form', 'project-payment', 'upgrade-arc', 'project-creation', 'actions', 'cc_basepoints'];
    public static notification_only_close = false;
    public static final_date_to_update_string_val = '';
    public static nextPagePortfolios: any = '';
    public static score_version_name_for_compare: any = null;
    public static score_version_id_for_compare: any = null;
    public static portfolios_count: number = 0;
    public static mapAddress: string = '';
    public static login_modal_text: string = '';
    public static login_btn_text: string = '';
    public static user_id: string = '';
    public static password: string = '';
    public static privacyCheckStatus: boolean = false;
    public static error_flag: boolean = false;
    public static success_flag: boolean = false;
    public static disable_login: boolean = false;
    public static accept_agreement_flag: boolean = false;
    public static login_app_redirect: string = '';
    public static error_msz: string = '';
    public static success_msz: string = '';
    public static sso_error: string = 'Unable to verify user.';
    public static login_modal_button_flag: boolean = false;
    public static widget_data: any = {
        queue: []
    };

    public static project_apps: string[] = ['measures', 'measure-form', 'actions', 'actions-form', 'project-overview', 'project-performance', 'data-input', 'models', 'project-tools-home', 'performance-certificates', 'purchase-certificates', 'climate-risk', 'financial-models', 'create-models', 'advanced-scoring', 'play-to-zero', 'efficiency-analysis', 're-entry', 'reports', 'team', 'project', 'apps', 'billing', 'agreement', 'settings', 'score-version'];
    public static portfolio_apps: string[] = ['portfolio-overview', 'portfolio-performance', 'portfolio-reports', 'portfolio', 'portfolio-team', 'activity'];
    public static admin_apps: string[] = ['admin_team', 'team_export', 'analysis', 'subscription_discount', 'cancel-subscription', 'admin_export', 'admin_certification', 'review', 'test_project', 'recompute_score', 'sync_project', 'admin_score_version', 'return_review', 'user_data_masking', 'recompute_improvement_scores', 'downgrade_score_version', 'recompute_reentry_score', 'offline_subscription', 'partial_maintenance', 'data_transfer', 'transit_survey', 'admin_deactivate', 'recompute_analysisinfo', 'covid_flag', 'admin_pre_payment'];
    public static show_navbar: boolean = false;
    public static hide_navbar: boolean = false;
    public static from_search: boolean = false;
    public static agreement_project: any = {};
    public static agreement_portfolio: any = {};
    public static dynamic_row: string = '';
    public static score_version_help_left: any;
    public static score_version_help_right: any;

    public static state: any = (param: string) => {
        if (this.state_params != null) {
            return this.state_params[param];
        }
    };

    public static sort_col = 0;
    public static sort_order = 'desc';

    public static config = {
        name: 'arc',
        title: 'arc',
        version: '1.0.0',
        debug: true,
        settings:
        {
            colors:
            {
                white: '#fff',
                black: '#000',
                'gray-light': '#999',
                'gray-lighter': '#eee',
                gray: '#666',
                'gray-dark': '#343434',
                'gray-darker': '#222',
                'gray-semi-light': '#777',
                'gray-semi-lighter': '#ddd',
                'brand-primary': '#5d8fc2',
                'brand-success': '#64bd63',
                'brand-warning': '#f0b518',
                'brand-danger': '#dd5826',
                'brand-info': '#5dc4bf'
            },
            screens:
            {
                'xs-max': 767,
                'sm-min': 768,
                'sm-max': 991,
                'md-min': 992,
                'md-max': 1199,
                'lg-min': 1200
            },
            navCollapseTimeout: 2500,
            subscription_key: this.sub_key,
            logout_url: this.logout_url,
            logout_url_jp: this.logout_url_jp,
            one_drive_key: 'b21b6a8f-007e-45c5-958a-6362a6bf02f1',
            one_drive_password: 'uliocMJ804;@rdSGZRJ65^#',
            google_developerKey: 'AIzaSyDwZV5TZd3xjoXmo_-26kent98c4oEugw8',
            google_clientId: '389952595752-9pb4p4e7hphi3ejtcelk4g5dko5qg1nq.apps.googleusercontent.com',
            google_scope: ['https://www.googleapis.com/auth/drive.readonly'],
            registration_agreement: '../../../../assets/pdf/registration_agreement.htm',
            certification_agreement: '../../../../assets/pdf/certification_agreement.htm',
            parksmart_registration_agreement: '../../../../assets/pdf/parksmart_registration_agreement.htm',
            addendum_agreement: '../../../../assets/pdf/addendum_agreement.htm',
            registration_pdf: '../../../../assets/pdf/registration_agreement.pdf',
            certification_pdf: '../../../../assets/pdf/certification_agreement.pdf',
            parksmart_registration_pdf: '../../../../assets/pdf/parksmart_registration_agreement.pdf',
            sites_registration_agreement: '../../../../assets/pdf/sites_registration_agreement.htm',
            sites_registration_pdf: '../../../../assets/pdf/sites_registration_agreement.pdf',
            addendum_pdf: '../../../../assets/pdf/addendum_agreement.pdf',
            static_credits: ['SS103', 'MR105', 'MR106'],
            rating_systems: this.rating_systems,
            LO_URL: this.LO_URL,
            LOV2_URL: this.LOV2_URL,
            animation_url: this.animation_url,
            resource_url: 'https://arc.gbci.org/resources',
            resource_credits: ['SS103', 'WE103', 'MR105', 'MR106', 'MR121', 'MR116', 'MR122', 'EQ103', 'EQ108', 'EQ120', 'EQ122', 'EQ126', 'EQ127', 'EQ128'],
            FRONTEND_BASE_URL: this.FRONTEND_BASE_URL,
            DEMO_FLAG: this.DEMO_FLAG,
            ENV_FLAG: this.ENV_FLAG,
            banner_message: this.banner_message,
            need_banner: this.need_banner,
            LO_PROXY_URL: this.LO_PROXY_URL,
            RS_V4_1: ['LEED v4.1 O+M: EB', 'LEED v4.1 O+M: Interiors', 'LEED v4.1 BD+C: CS', 'LEED v4.1 BD+C: DC', 'LEED v4.1 BD+C: HC', 'LEED v4.1 BD+C: HP', 'LEED v4.1 BD+C: NC', 'LEED v4.1 BD+C: Retail', 'LEED v4.1 BD+C: SC', 'LEED v4.1 BD+C: WDC', 'LEED v4.1 ID+C: CI', 'LEED v4.1 ID+C: HP', 'LEED v4.1 ID+C: Retail'],
            NO_REVIEW_RS: ['LEED v4.1 BD+C: CS', 'LEED v4.1 BD+C: DC', 'LEED v4.1 BD+C: HC', 'LEED v4.1 BD+C: HP', 'LEED v4.1 BD+C: NC', 'LEED v4.1 BD+C: Retail', 'LEED v4.1 BD+C: SC', 'LEED v4.1 BD+C: WDC', 'LEED v4.1 ID+C: CI', 'LEED v4.1 ID+C: HP', 'LEED v4.1 ID+C: Retail', 'LEED v4.1 Cities: Existing', 'LEED v4.1 Cities: Plan and Design', 'LEED v4.1 Communities: Existing', 'LEED v4.1 Communities: Plan and Design'],
            NO_REVIEW_COUNTRY: ['RU', 'BY'],
            landingPageURL: this.landingPageURL,
            usgbc_basic_url: this.usgbc_basic_url,
            show_paynow_mat: this.show_paynow_mat,
            LO_RS_city_comm: ['LEED v4.1 Cities: Existing', 'LEED v4.1 Cities: Plan and Design', 'LEED v4.1 Communities: Existing', 'LEED v4.1 Communities: Plan and Design'],
            space_type_notification: ['Industrial Manufacturing', 'Laboratory', 'Data Center', 'Warehouse: Nonrefrigerated Distribution/Shipping', 'Warehouse: Refrigerated', 'Warehouse: Self Storage Units', 'Warehouse: General'],
            space_type_gsa: ['Circulation Space', 'Lodging: Dormitory', 'Lodging: Hotel/Motel/Resort, Full Service', 'Lodging: Hotel/Motel/Resort, Limited Service', 'Lodging: Hotel/Motel/Resort, Select Service', 'Lodging: Inn', 'Lodging: Other lodging', 'Office: Administrative/Professional', 'Office: Financial', 'Office: Government', 'Office: Medical (Non-Diagnostic)', 'Office: Mixed-Use', 'Office: Other Office', 'Public Assembly: Convention Center', 'Public Assembly: Entertainment', 'Public Assembly: Library', 'Public Assembly: Museum', 'Public Assembly: Other Assembly', 'Public Assembly: Recreation', 'Public Assembly: Social/Meeting', 'Public Assembly: Stadium/Arena', 'Retail: Bank Branch', 'Retail: Convenience Store', 'Retail: Enclosed Mall', 'Retail: Fast Food', 'Retail: Grocery Store/Food Market', 'Retail: Open Shopping Center', 'Retail: Other Retail', 'Retail: Restaurant/Cafeteria', 'Service: Repair Shop', 'Service: Vehicle Service/Repair', 'Service: Vehicle Storage/Maintenance', 'Transit: Depot', 'Transit: Line', 'Transit: Maintenance/Storage', 'Transit: Office', 'Transit: Other', 'Transit: Station', 'Transit: Station/Elevated', 'Transit: Station/Open Air Ground Level', 'Transit: Station/Underground', 'Transit: System', 'Warehouse: Nonrefrigerated Distribution/Shipping', 'Warehouse: Refrigerated', 'Warehouse: Self Storage Units', 'Warehouse: General', 'Other'],
            LOV2_RS: ['LEED-EB 1.0 Pilot', 'LEED-CI 1.0 Pilot', 'LEED-CS 1.0 Pilot', 'LEED-CS 2.0', 'LEED-NC 1.0 Pilot', 'LEED-NC 2.0', 'LEED-NC 2.1', 'LEED-CI 2.0', 'LEED-EB 2.0', 'LEED-EB O&M', 'LEED-NC 2.2', 'LEED BD+C: Core and Shell 2.0'],
            v1_applicable_date: this.v1_applicable_date,
            insight_url: this.insight_url,
            it_subscription_key: this.it_subscription_key,
            all_arc_material: this.all_arc_material,
            s3_bucket_url: this.s3_bucket_url,
            insights_home: '//insight.arconline.io/',
            test_project_keywords: ['test', 'experiment'],
            arc_pro_label: 'Arc Essentials',
            arc_gsa_label: 'Play to Zero',
            show_insights: ['skanska.com'],
            city_help_energy: [
                {
                    city: '40',
                    community: '40',
                    prereq: 'Prerequisite',
                },
                {
                    city: '41 - 44',
                    community: '41 - 44',
                    prereq: '1',
                },
                {
                    city: '45 - 49',
                    community: '45 - 48',
                    prereq: '2',
                },
                {
                    city: '50 - 53',
                    community: '49 - 52',
                    prereq: '3',
                },
                {
                    city: '54 - 57',
                    community: '53 - 56',
                    prereq: '4',
                },
                {
                    city: '58 - 61',
                    community: '57 - 60',
                    prereq: '5',
                },
                {
                    city: '62 - 66',
                    community: '61 - 64',
                    prereq: '6',
                },
                {
                    city: '67 - 70',
                    community: '65 - 68',
                    prereq: '7',
                },
                {
                    city: '71 - 74',
                    community: '69 - 70',
                    prereq: '8',
                },
                {
                    city: '75 - 79',
                    community: '71 - 73',
                    prereq: '9',
                },
                {
                    city: '80 - 83',
                    community: '74 - 76',
                    prereq: '10',
                },
                {
                    city: '84 - 87',
                    community: '77 - 79',
                    prereq: '11',
                },
                {
                    city: '88 - 91',
                    community: '80 - 82',
                    prereq: '12',
                },
                {
                    city: '92 - 96',
                    community: '83 - 85',
                    prereq: '13',
                },
                {
                    city: '97 - 100',
                    community: '86 - 88',
                    prereq: '14',
                },
                {
                    city: '',
                    community: '89 - 91',
                    prereq: '15',
                },
                {
                    city: '',
                    community: '92 - 94',
                    prereq: '16',
                },
                {
                    city: '',
                    community: '95 - 97',
                    prereq: '17',
                },
                {
                    city: '',
                    community: '98 - 100',
                    prereq: '18',
                }],
            city_help_water: [
                {
                    city: '40',
                    community: '40',
                    prereq: 'Prerequisite',
                },
                {
                    city: '41 - 50',
                    community: '41 - 44',
                    prereq: '1',
                },
                {
                    city: '51 - 60',
                    community: '45 - 48',
                    prereq: '2',
                },
                {
                    city: '61 - 70',
                    community: '49 - 52',
                    prereq: '3',
                },
                {
                    city: '71 - 80',
                    community: '53 - 56',
                    prereq: '4',
                },
                {
                    city: '81 - 90',
                    community: '57 - 60',
                    prereq: '5',
                },
                {
                    city: '91 - 100',
                    community: '61 - 64',
                    prereq: '6',
                }],
            city_help_waste: [
                {
                    city: '40',
                    community: '40',
                    prereq: 'Prerequisite',
                },
                {
                    city: '41 - 55',
                    community: '41 - 52',
                    prereq: '1',
                },
                {
                    city: '56 - 70',
                    community: '53 - 64',
                    prereq: '2',
                },
                {
                    city: '71 - 85',
                    community: '65 - 76',
                    prereq: '3',
                },
                {
                    city: '86 - 100',
                    community: '77 - 88',
                    prereq: '4',
                },
                {
                    city: '',
                    community: '89 - 100',
                    prereq: '5',
                }]
        },

        /**
         * Application state. May be changed when using.
         * Synced to Local Storage
         */
        state:
        {
            /**
             * whether navigation is static (prevent automatic collapsing)
             */
            'nav-static': false
        }
    };
}
