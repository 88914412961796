import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@auth0/auth0-angular';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RedirectGuard } from './core/guards/redirect.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationInterceptor } from './core/interceptors/authentication.interceptor';

import { AUTH0_CONFIG } from './app.constants';
import { BootstrapperService } from './core/services/bootstrapper/bootstrapper.service';

export let AppInjector: Injector;

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        LayoutModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule.forRoot(AUTH0_CONFIG),
    ],
    providers: [
        RedirectGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: BootstrapperService) => () => configService.load(),
            deps: [
                BootstrapperService
            ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private readonly injector: Injector) {
        AppInjector = injector;
    }
}
