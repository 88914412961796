import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppData {
    public set(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public get(key: string): any {
        if (sessionStorage.getItem(key) === 'undefined') {
            return undefined;
        }

        const response = JSON.parse(sessionStorage.getItem(key) as any);
        if (key === 'buildingData' && response === undefined) {
            return {};
        }

        return response;
    }

    public delete(key: string): void {
        sessionStorage.removeItem(key);
    }

    public clearAll(): void {
        sessionStorage.clear();
    }

    public setLocal(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public getLocal(key: string): any {
        if (localStorage.getItem(key) === null) {
            return undefined;
        }

        let response;
        try {
            response = JSON.parse(localStorage.getItem(key) as any);
        }
        catch(e) {
            response = localStorage.getItem(key)
        }
        if (response === undefined) {
            return {};
        }

        return response;
    }

    public deleteLocal(key: string): void {
        localStorage.removeItem(key);
    }
}
