import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    public layoutType: BehaviorSubject<string> = new BehaviorSubject<string>('default');
    public show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public showBreadcrumb: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public hideLayout(): void {
        this.show.next(false);
    }

    public showLayout(): void {
        this.show.next(true);
    }

    public hideBreadcrumbs(): void {
        this.showBreadcrumb.next(false);
    }

    public showBreadcrumbs(): void {
        this.showBreadcrumb.next(true);
    }

    public setLayout(layoutType: string): void {
        this.layoutType.next(layoutType);
    }
}
