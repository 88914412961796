import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppData } from '@shared/legacy-services/appdata.service';
import { Projects } from '@shared/legacy-services/projects.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
    selector: 'usgbc-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit, OnDestroy {
    type!: string;
    
    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly layoutService: LayoutService,
        private readonly router: Router,
        private readonly appData: AppData,
        private projects: Projects,
    ) {
        this.type = this.activatedRoute.snapshot.data['type'];
    }

    ngOnDestroy(): void {
        this.layoutService.setLayout('default');
    }

    ngOnInit(): void {
        this.layoutService.setLayout('showBackground');

        this.activatedRoute.params.subscribe((params: any) => {
            this.appData.set('leed_id', params.leed_id);
            let leed_id = params.leed_id;
            this.projects.getAssetDetails(leed_id).subscribe(() => {
                if(!(
                    this.appData.get('user_role') == 'arc_basic' ||
                    this.appData.get('buildingData').arctool_permission
                )) {
                    this.router.navigate(
                        ['properties/' + leed_id + '/score-modeling/landing']
                    );
                }
            });
        });
    }

    purchaseSubscription() {
        this.router.navigate(['arc-essentials']);
    }
    
}
