import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RedirectService {
    constructor() {}

    redirectToHub(url: string): void {
        window.open(url, '_self');
    }

    getHUBURL(): string {
        return environment.HUB_URL;
    }
}
