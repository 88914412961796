import { Injectable } from '@angular/core';
import { API } from '@shared/legacy-services/api.service';
import { AppData } from '@shared/legacy-services/appdata.service';
import { forEach } from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PerformanceService {
    TYPE: any = {
        energy: 'energy_score',
        water: 'water_score',
        waste: 'waste_score',
        transportation: 'transport_score',
        human_experience: 'humanexperience_score'
    };

    constructor(
        private readonly api: API,
        private readonly appData: AppData
    ) {}

    getImprovement(leedId: number, type: string) {
        const url = '/assets/property:' + leedId + '/scores/improvement/?performance_end_date=' + this.appData.get('buildingData').performance_end_date;

        return this.api.get(url).pipe(
            map(data => data = data[type])
        );
    }

    getSourceEnergy(leedId: number) {
        let url = '/assets/property:' + leedId + '/resampled/source_energy/';
        url += '?start_date=' + this.appData.get('buildingData').performance_start_date + '&end_date=' + this.appData.get('buildingData').performance_end_date;

        return this.api.get(url).pipe(
            map(data => {
                const total = {
                    total: 0
                };

                forEach(data, value => {
                    total.total += isNaN(parseInt(value.reading)) ? 0 : value.reading;
                    value['startDate'] = value.start_date;
                    value['endDate'] = value.end_date;

                    delete value.start_date;
                    delete value.end_date;
                });

                return {
                    unit: data[0].unit,
                    sourceEnergy: data,
                    dateRange: moment(this.appData.get('buildingData').performance_start_date).format('MMM DD, YYYY') + ' - ' + moment(this.appData.get('buildingData').performance_end_date).format('MMM DD, YYYY'),
                    total
                };
            })
        );
    }

    getEmissionsIntensity(leedId: number) {
        let url = '/assets/property:' + leedId + '/resampled/carbon_emission/';
        url += '?start_date=' + this.appData.get('buildingData').performance_start_date + '&end_date=' + this.appData.get('buildingData').performance_end_date;
        url += '&intensity=gross_area';

        return this.api.get(url).pipe(
            map(data => {
                const total = {
                    scope1: 0,
                    scope2: 0,
                    total: 0
                };

                forEach(data, value => {
                    total.scope1 += isNaN(parseInt(value.scope1)) ? 0 : value.scope1;
                    total.scope2 += isNaN(parseInt(value.scope2)) ? 0 : value.scope2;

                    value.reading = (isNaN(parseInt(value.scope1)) ? 0 : value.scope1) + (isNaN(parseInt(value.scope2)) ? 0 : value.scope2);
                    value['startDate'] = value.start_date;
                    value['endDate'] = value.end_date;

                    delete value.start_date;
                    delete value.end_date;
                });

                total.total = total.scope1 + total.scope2;
                total.total = total.total / 12;
                total.total = parseFloat((total.total).toFixed(2));

                return {
                    unit: data[0].unit,
                    emissionsIntensity: data,
                    dateRange: moment(this.appData.get('buildingData').performance_start_date).format('MMM DD, YYYY') + ' - ' + moment(this.appData.get('buildingData').performance_end_date).format('MMM DD, YYYY'),
                    total
                };
            })
        );
    }

    getCumulativeEmissions(leedId: number) {
        let url = '/assets/property:' + leedId + '/resampled/emissions/';
        url += '?start_date=' + this.appData.get('buildingData').performance_start_date + '&end_date=' + this.appData.get('buildingData').performance_end_date;

        return this.api.get(url).pipe(
            map(data => {
                const total = {
                    scope1: 0,
                    scope2: 0,
                    both: 0
                };

                forEach(data, value => {
                    total.scope1 += isNaN(parseInt(value.scope1)) ? 0 : value.scope1;
                    total.scope2 += isNaN(parseInt(value.scope2)) ? 0 : value.scope2;

                    value['startDate'] = value.start_date;
                    value['endDate'] = value.end_date;
                    value['unit'] = value.carbon_unit;

                    delete value.start_date;
                    delete value.end_date;
                });

                total.both = total.scope1 + total.scope2;

                return {
                    emissions: data,
                    dateRange: moment(this.appData.get('buildingData').performance_start_date).format('MMM DD, YYYY') + ' - ' + moment(this.appData.get('buildingData').performance_end_date).format('MMM DD, YYYY'),
                    total
                };
            })
        );
    }

    getElectricityPercentage(leedId: number) {
        let url = '/assets/property:' + leedId + '/resampled/electricity/';
        url += '?start_date=' + this.appData.get('buildingData').performance_start_date + '&end_date=' + this.appData.get('buildingData').performance_end_date;
        url += '&intensity=energy';

        return this.api.get(url).pipe(
            map(data => {

                forEach(data, value => {
                    value.reading = isNaN(parseInt(value.reading)) ? 0 : value.reading;
                    value['startDate'] = value.start_date;
                    value['endDate'] = value.end_date;
                    value['value'] = value.reading;

                    delete value.start_date;
                    delete value.end_date;
                });

                return {
                    unit: '%',
                    monthlyAverage: data,
                    maxima: {
                        score: 100,
                    },
                    dateRange: moment(this.appData.get('buildingData').performance_start_date).format('MMM DD, YYYY') + ' - ' + moment(this.appData.get('buildingData').performance_end_date).format('MMM DD, YYYY')
                };
            })
        );
    }

    getMonthlyAverageScore(leedId: number, type: string) {
        let url = '/assets/property:' + leedId + '/resampled/' + this.TYPE[type] + '/';
        url += '?start_date=' + this.appData.get('buildingData').performance_start_date + '&end_date=' + this.appData.get('buildingData').performance_end_date;

        return this.api.get(url).pipe(
            map(data => {
                let total = 0;
                let length = 0;

                forEach(data, value => {
                    if (!isNaN(parseInt(value.score))) {
                        length += 1;
                        total += parseInt(value.score);
                    }
                    value.value = isNaN(parseInt(value.score)) ? 0 : value.score;

                    value['startDate'] = value.start_date;
                    value['endDate'] = value.end_date;

                    delete value.start_date;
                    delete value.end_date;
                });

                length = length == 0 ? 1 : length;
                total /= length;

                return {
                    dateRange: moment(this.appData.get('buildingData').performance_start_date).format('MMM DD, YYYY') + ' - ' + moment(this.appData.get('buildingData').performance_end_date).format('MMM DD, YYYY'),
                    type,
                    total: total,
                    monthlyAverage: data,
                    maxima: 100
                };
            })
        );
    }

    getPerformanceScore(leedId: number, date: string) {
        return this.api.get(
            '/assets/property:' + leedId + '/scores/v2/?at=' + date
        );
    }
}
