import { get, set } from 'lodash';

import { Component, Input, OnInit } from '@angular/core';

import { DATE_FORMAT, IMPORVEMENT_SCORE } from './improvement-score.constants';
import { GlobalService } from '@shared/legacy-services/global.service';

@Component({
    selector: 'usgbc-improvement-score',
    templateUrl: './improvement-score.component.html',
    styleUrls: ['./improvement-score.component.scss']
})
export class ImprovementScoreComponent implements OnInit {
    @Input() data!: any;
    @Input() name: string = '';
    @Input() sustainabilityType!: any;
    @Input() layout: 'expanded' | 'compact' = 'expanded';
    @Input() titleTooltip!: string;
    @Input() dataTooltip!: string;

    public dateFormat = DATE_FORMAT;
    public showQualityLegend!: boolean;
    public scores!: Array<Array<{
        score?: number;
        quality?: any;
        reduction?: number;
        title?: string;
        subtitle?: string;
        invertQuality?: boolean;
    }>>;

    public get = get;

    constructor(
        public globalService: GlobalService
    ) {}

    ngOnInit(): void {
        this.scores = IMPORVEMENT_SCORE[
            this.sustainabilityType as keyof typeof IMPORVEMENT_SCORE
        ]?.map((row) => row.map((cell) => {
            const data = {};

            Object.entries(cell).forEach(([key, value]) => {
                let dataValue = (key === 'title' || key === 'subtitle' || key === 'invertQuality')
                    ? value
                    : get(this.data, value);

                if (key === 'quality') {
                    this.showQualityLegend = true;
                }

                if (key === 'subtitle') {
                    const unit = get(cell, 'unit', '');
                    const perUnit = get(cell, 'perUnit', '');

                    dataValue = dataValue
                        .replace('unit', get(this.data, unit, ''))
                        .replace('perUnit', get(this.data, perUnit, ''));
                }

                set(data, key, dataValue);
            });

            return data;
        }));
    }
}
