<div class="custom-card-default">
    <div class="container">
        <div class="header">
            <div class="title">
                <h4 *ngIf="layout==='compact'">{{ name  }}</h4>
                <div
                    class="icon-text"
                    *ngIf="layout==='expanded'">
                    <h5>Improvement score</h5>
                    <mat-icon
                        *ngIf="titleTooltip"
                        class="info-icon"
                        matTooltip="Info about the improvement score">
                        info_outline
                    </mat-icon>
                </div>
            </div>
            <ng-container *ngIf="layout==='expanded'; else legend"></ng-container>
        </div>
        <div
            class="content"
            [ngClass]="{'row': layout==='expanded', 'column': layout==='compact'}">
            <div
                class="improvement-header"
                [ngClass]="{'full-width': layout==='compact'}">
                <div
                    class="score"
                    *ngIf="data">
                    <span
                        class="text-13"
                        *ngIf="layout==='compact'">Estimated improvement score</span>
                    <span class="score-value">{{globalService.formatNumber(data.score, 0, true)}}</span>
                    <span class="total">/100</span>
                </div>
                <ng-container *ngIf="layout==='compact'; else legend"></ng-container>
            </div>
            <table class="improvement-table">
                <thead class="thead">
                    <tr class="tr">
                        <th class="th"></th>
                        <th class="th">
                            <span class="table-title">Baseline</span>
                            <span
                                class="table-subtitle"
                                *ngIf="data">
                                {{ data.baseline_start_date | date : dateFormat }} - {{ data.baseline_end_date | date : dateFormat }}
                            </span>
                        </th>
                        <th class="th">
                            <span class="table-title">Performance</span>
                            <span
                                class="table-subtitle"
                                *ngIf="data">{{ data.performance_start_date  | date : dateFormat }} - {{ data.performance_end_date  | date : dateFormat }}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    <tr
                        class="tr"
                        *ngFor="let row of scores">
                        <ng-container *ngFor="let scoreData of row">
                            <td
                                *ngIf="scoreData.title"
                                scope="row"
                                class="td">
                                <div class="content row-header">
                                    <span class="table-title">{{scoreData.title}}</span>
                                    <span class="table-subtitle">{{scoreData.subtitle}}</span>
                                </div>
                            </td>
                            <td
                                *ngIf="scoreData?.score !== undefined"
                                class="td">
                                <div class="content">
                                    <usgbc-info-marker
                                        *ngIf="scoreData.quality"
                                        [quality]="scoreData.quality || 'neutral'">
                                        {{globalService.formatNumber(scoreData?.score, 6)}}
                                    </usgbc-info-marker>
                                    <ng-container *ngIf="scoreData.quality === undefined">{{globalService.formatNumber(scoreData?.score, 6)}}</ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #legend>
    <div
        class="legend"
        *ngIf="showQualityLegend">
        <div class="legend-title">
            Data Quality
            <mat-icon
                *ngIf="dataTooltip"
                class="info-icon"
                matTooltip="Info about the data quality">
                info_outline
            </mat-icon>
        </div>
        <div class="legend-score">
            <usgbc-info-marker
                class="score-quality"
                [quality]="'poor'">
                Poor
            </usgbc-info-marker>
            <usgbc-info-marker
                class="score-quality"
                [quality]="'good'">
                Fair
            </usgbc-info-marker>
            <usgbc-info-marker
                class="score-quality"
                [quality]="'best'">
                Good
            </usgbc-info-marker>
        </div>
    </div>
</ng-template>
