import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { InitialsPipe } from './pipes';
import { FormatNumberPipe } from './pipes/formatNumber/format-number.pipe';
import { GlobalService } from './legacy-services/global.service';
import { API } from './legacy-services/api.service';
import { AppData } from './legacy-services/appdata.service';
import { Projects } from './legacy-services/projects.service';
import { AuthenticationService } from '../core/services/authentication/authentiaction.service';
import { PerformanceService } from '../view/property/services/performance.service';
import { ImprovementScoreComponent } from './components/improvement-score/improvement-score.component';
import { CommonModule } from '@angular/common';
import { InfoMarkerComponent } from './components/info-marker/info-marker.component';
import { ValidationService } from './legacy-services/validation.service';

import {
    CumulativeConsumptionModule,
    GenericMeterBarchartModule,
    MonthlyAverageScoreModule,
    DoughnutChartModule
} from '@usgbc/usgbc-hub-fe-library';
import { NotificationComponent } from './components/notification/notification.component';
import { LandingComponent } from './components/landing/landing.component';
import { SvgIconModule } from './svg-icon.module';

const LIBRARY_COMPONENTS = [
    CumulativeConsumptionModule,
    GenericMeterBarchartModule,
    MonthlyAverageScoreModule,
    DoughnutChartModule,
    MatButtonModule,
    MatSnackBarModule
];

const declarations = [
    InitialsPipe,
    FormatNumberPipe,
    ImprovementScoreComponent,
    InfoMarkerComponent,
    NotificationComponent,
    LandingComponent
];

@NgModule({
    declarations,
    exports: [...declarations, ...LIBRARY_COMPONENTS],
    imports: [
        HttpClientModule,
        MatDialogModule,
        MatIconModule,
        MatButtonToggleModule,
        MatTooltipModule,
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatCardModule,
        SvgIconModule,
        ...LIBRARY_COMPONENTS
    ],
    providers: [
        GlobalService,
        API,
        AppData,
        Projects,
        AuthenticationService,
        PerformanceService,
        ValidationService
    ]
})
export class SharedModule {}
