import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppData } from './appdata.service';
import { Config } from 'src/app/core/config';

@Injectable({ providedIn: 'root' })
export class API {
    constructor(
        private readonly http: HttpClient,
        private readonly appData: AppData
    ) {}

    reset(): void {
        delete Config.headers.Authorization;
        this.appData.set('headers', Config.headers);
    }

    setAuth(token: string): void {
        Config.headers.Authorization = 'Bearer ' + String(token);
        this.appData.set('headers', Config.headers);
    }

    post(url: string, payload: any, headers?: any): Observable<any> {
        if (headers === undefined) {
            headers = Config.headers;
        } else {
            headers = headers.headers;
        }

        return this.http.post<any>(Config.basic_api_url + url, payload, { headers });
    }

    put(url: string, payload: any, header?: any): Observable<any> {
        if (header === undefined) {
            header = Config.headers;
        } else {
            header = header.headers;
        }

        return this.http.put<any>(Config.basic_api_url + url, payload, { headers: header });
    }

    get(url: string, responseType?: string, header?: any): Observable<any> {
        if (responseType === undefined) {
            responseType = 'json';
        }

        if (header === undefined) {
            header = new HttpHeaders(Config.headers);
        } else {
            header = new HttpHeaders(header.headers);
        }

        return this.http.get<any>(Config.basic_api_url + url, { headers: header, responseType: responseType as 'json' });
    }

    delete(url: string, payload: any): Observable<any> {
        const options = {
            body: payload,
            headers:
            {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': Config.sub_key,
                Authorization: 'Bearer ' + String(this.appData.get('token'))
            }
        };

        return this.http.delete<any>(Config.basic_api_url + url, options);
    }

    getAsset(url: string, responseType?: string): Observable<any> {
        if (responseType === undefined) {
            responseType = 'json';
        }

        return this.http.get<any>(url, { responseType: responseType as 'json' });
    }

    async(url: string[], payload?: any, type?: string): Observable<any> {
        const apis = [];
        for (const i in url) {
            if (payload !== undefined) {
                if (type === undefined || type === 'post') {
                    apis.push(
                        this.post(url[i], payload)
                    );
                } else if (type === 'delete') {
                    apis.push(
                        this.delete(url[i], payload)
                    );
                }
            } else {
                apis.push(
                    this.get(url[i])
                );
            }
        }

        return forkJoin(apis);
    }
}
