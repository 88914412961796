import { Component, Input } from '@angular/core';

@Component({
    selector: 'usgbc-info-marker',
    templateUrl: './info-marker.component.html',
    styleUrls: ['./info-marker.component.scss']
})

export class InfoMarkerComponent {
    @Input() quality: any = 'neutral';
    @Input() percentage!: number;
    @Input() invertQuality: boolean = false;
}
