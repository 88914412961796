import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { RedirectService } from '@shared/services/redirect.service';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly redirect: RedirectService
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if(state.url == '/user') {
            this.redirect.redirectToHub(this.redirect.getHUBURL() + "/" + state.url);
        }
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(
            (data) => {
                this.redirect.redirectToHub(this.redirect.getHUBURL() + "/" + state.url);
            }
        );

        return true;
    }
}
