<mat-sidenav-container
    class="main-sidenav"
    autosize="false">
        <mat-sidenav
        *ngIf="showLayout"
        #sidenav
        mode="side"
        class="app-sidenav"
        [class.minimized]="isMinimized"
        opened="true"
        [autoFocus]="false"
        (mouseover)="isMinimized = false"
        (mouseleave)="isMinimized = true">

        <div class="navigation-menu">
            <mat-nav-list class="main-menu top">
                <ng-container *ngFor="let menuItem of menuItems;">
                    <mat-list-item
                        *ngIf="menuItem.position === 'top'"
                        (click)="routeTo(menuItem.route)"
                        [ngClass]="menuItem.label == 'Properties' ? 'active' : ''">
                        <div class="icon-with-text">
                            <div class="item-border"></div>
                            <div class="tex-wrapper">
                                <div
                                    class="icon"
                                    [class.minimized]="minimizeItem">
                                    <mat-icon class="material-icons-outlined material-symbols-outlined">{{ menuItem.icon }}</mat-icon>
                                </div>
                                <div
                                    class="icon-text"
                                    [class.minimized]="minimizeItem">
                                    {{ menuItem.label }}
                                </div>
                            </div>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>
    
            <mat-nav-list class="main-menu bottom">
                <mat-divider
                    [class.minimized]="minimizeItem"
                    class="divider">
                </mat-divider>
                <ng-container *ngFor="let menuItem of menuItems; trackBy: trackByIndex; let $last = last; let i = index">
                    <mat-list-item
                        *ngIf="menuItem.position === 'bottom'"
                        (click)="routeTo(menuItem.route)"
                        class="main-menu-list-bottom">
    
                        <div
                            class="icon-with-text"
                            [attr.data-tg-title]="menuItem.tourLabel"
                            [attr.data-tg-tour]="menuItem.description"
                            [attr.data-tg-order]="menuItem.id">
                            <div class="item-border"></div>
                            <div class="tex-wrapper">
                                <div
                                    class="icon"
                                    [class.minimized]="minimizeItem">
    
                                    <mat-icon
                                        *ngIf="menuItem.icon"
                                        class="material-icons-outlined material-symbols-outlined">
                                        {{ menuItem.icon }}
                                    </mat-icon>
    
                                    <mat-icon
                                        *ngIf="menuItem.img"
                                        [svgIcon]="menuItem.img">
                                    </mat-icon>
    
                                </div>
                                <div
                                    class="icon-text"
                                    [class.minimized]="minimizeItem">
                                    {{ menuItem.label }}
                                </div>
                            </div>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <toolbar *ngIf="showLayout"></toolbar>
        <div
            class="main-content"
            [ngClass]="{'main-content--wide' : layoutType === 'showBackground'}"
            [class.paddingless]="!showLayout">
            <div class="background">
                <router-outlet name="background"></router-outlet>
            </div>
            <div class="left-content" [class.hideLayout]="!showLayout">
                <breadcrumb [hidden]="!showBreadcrumb"></breadcrumb>
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>