import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentiaction.service';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BootstrapperService {
    resolve: any;

    constructor(
        private readonly authenticationService: AuthenticationService,
    ) {}

    async load(): Promise<any> {
        await new Promise<void>((resolve, reject) => {
            this.resolve = resolve;
            this.isLoggedIn().subscribe(() => {});
        });
    }

    isLoggedIn(): Observable<boolean> {
        return this.authenticationService.isLoggedIn().pipe(
            map((isLoggedIn: boolean) => {

                if(window.location.pathname.indexOf('plaque') != -1) {
                    this.resolve();
                    return true;
                }

                if (!isLoggedIn) {
                    this.authenticationService.login();

                    return false;
                }

                if (isLoggedIn) {
                    this.authenticationService.getAuth0Token().subscribe(
                        (token) => {
                            this.authenticationService.auth0Token = token;

                            this.authenticationService.getJWT(token)
                                .subscribe(jwtToken => {
                                    this.authenticationService.setJWTToken(jwtToken);
                                    this.resolve();
                                });
                        }
                    );

                    return true;
                }

                return false;
            })
        );
    }
}
