import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb/breadcrumb.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    breadcrumbs$: Observable<any>;
    breadcrumbsInit: boolean = false;

    constructor(
        private readonly breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute
    ) {
        this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    }

    ngOnInit(): void {
        this.breadcrumbs$.subscribe(
            _event => {
                const root = this.route.snapshot;
                if(_event.length == 0 && !this.breadcrumbsInit) {
                    this.breadcrumbsInit = true;
                    this.breadcrumbService.initBreadcrumb(root);
                }
            }
        );
    }

    redirectTo(url: string) {
        window.open(url, '_self');
    }
}
