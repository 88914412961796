import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
    exports: [MatIconModule],
})

/**
 *  To register and display custom icons used by the <mat-icon> component.
 *  Example:
 *  1st Save the .svg you can use "currentColor" on the svg to inherit color from the class
 *  2nd add the name with the prefix "custom-" and the URL where is the icon in customIcons:
 *    ['custom-badge-check', '../assets/icons/svg/custom-badge-check.svg'],
 *  3th use the icon example:
 *    <mat-icon [svgIcon]="'custom-badge-check'"></mat-icon>
 *
 */
export class SvgIconModule {
    customIcons: Array<[string, string]> = [
        ['custom-badge-check', '../assets/icons/svg/custom-badge-check.svg'],
        [
            'custom-office-building',
            '../assets/icons/svg/custom-office-building.svg',
        ],
        [
            'custom-switch-horizontal',
            '../assets/icons/svg/custom-switch-horizontal.svg',
        ],
        ['custom-credit-card', '../assets/icons/svg/custom-credit-card.svg'],
        ['custom-profile-icon', '../assets/icons/svg/custom-profile-icon.svg'],
        ['custom-p-menu', '../assets/icons/svg/custom-p-menu.svg'],
        ['custom-l-menu', '../assets/icons/svg/custom-l-menu.svg'],
    ];

    constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        this.customIcons.forEach(([iconName, iconPath]) => {
            iconRegistry.addSvgIcon(
                iconName,
                sanitizer.bypassSecurityTrustResourceUrl(iconPath)
            );
        });
    }
}
