import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/shared/interfaces/breadcrumb.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    public readonly breadcrumbs$ = new Observable<Breadcrumb[]>();
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    constructor(private readonly router: Router) {
        this.breadcrumbs$ = this._breadcrumbs$.asObservable();
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe((_event) => {
                const root = this.router.routerState.snapshot.root;
                const breadcrumbs: Breadcrumb[] = [];
                this.addBreadcrumb(root, [], breadcrumbs);
                this._breadcrumbs$.next(breadcrumbs);
            });
    }

    public initBreadcrumb(
        route: ActivatedRouteSnapshot
    ){
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(route, [], breadcrumbs);
        this._breadcrumbs$.next(breadcrumbs);
    }

    private addBreadcrumb(
        route: ActivatedRouteSnapshot,
        parentUrl: string[],
        breadcrumbs: Breadcrumb[]
    ): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (route) {
            const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (route.data['breadcrumb']) {
                let url = '/' + routeUrl.join('/');
                url = url == '/user' ? (environment.HUB_URL + '/home') : url;
                const breadcrumb = {
                    label: this.getLabel(route.data),
                    url: url,
                };
                breadcrumbs.push(breadcrumb);
            }

            if (route.firstChild) this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }
    }

    private getLabel(data: Data): any {
        return typeof data['breadcrumb'] === 'function' ? data['breadcrumb'](data) : data['breadcrumb'];
    }
}
