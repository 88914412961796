import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AppData } from '@shared/legacy-services/appdata.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentiaction.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
    public userName!: string;
    public changePasswordUrl!: string;

    constructor(
        private readonly appData: AppData,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly authorizationService: AuthenticationService
    ) {
        this.userName = this.appData.getLocal('user').first_name + ' ' + this.appData.getLocal('user').last_name;
        this.changePasswordUrl = `${environment.accounts_page}/change-password?redirect_uri=${this.document.URL}`;
    }

    logout(): void {
        this.authorizationService.logout();
    }
}
