import { AuthConfig } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

export const AUTH0_CONFIG: AuthConfig = {
    domain: environment.auth0_domain,
    clientId: environment.sso_client,
    authorizationParams: {
        redirect_uri: window.location.origin + '/login_middleware' + '?next=' + window.location.pathname,
    },
};
