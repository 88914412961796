<div class="modal-body"> 
    <div class=" row p20 fw-400">
        <b>Disclaimer</b><br><br>
            <p>
                © 2021 Four Twenty Seven, Inc. and/or its licensors and subsidiaries (collectively, “Four Twenty Seven”). All rights reserved.	
            </p>		
                        
            <p>
                Four Twenty Seven provides its customers with data, analytics and other solutions (collectively, “Research”) with respect to the physical climate and environmental risks associated with individual facilities, individual issuers, sectors and geographical boundaries.	
            </p>		
                        
            <p>
                FOUR TWENTY SEVEN’S RESEARCH DOES NOT ADDRESS NON-ESG FACTORS AND/OR RISKS, INCLUDING BUT NOT LIMITED TO: CREDIT RISK, LIQUIDITY RISK, MARKET VALUE RISK, OR PRICE VOLATILITY. FOUR TWENTY SEVEN’S RESEARCH DOES NOT CONSTITUTE STATEMENTS OF CURRENT OR HISTORICAL FACT. FOUR TWENTY SEVEN’S RESEARCH:<br>	
                (i) DOES NOT CONSTITUTE OR PROVIDE CREDIT RATINGS OR INVESTMENT OR FINANCIAL ADVICE;<br>		
                (ii) IS NOT AND DOES NOT PROVIDE RECOMMENDATIONS TO PURCHASE, SELL, OR HOLD PARTICULAR SECURITIES;<br>			
                AND (iii) DOES NOT COMMENT ON THE SUITABILITY OF AN INVESTMENT FOR ANY PARTICULAR INVESTOR. FOUR TWENTY SEVEN ISSUES ITS RESEARCH WITH THE EXPECTATION AND UNDERSTANDING THAT EACH INVESTOR WILL, WITH DUE CARE, MAKE ITS OWN STUDY AND EVALUATION OF EACH SECURITY THAT IS UNDER CONSIDERATION FOR PURCHASE, HOLDING, OR SALE.	
            </p>		
                        
            <p>
                FOUR TWENTY SEVEN’S RESEARCH IS NOT INTENDED FOR USE BY RETAIL INVESTORS AND IT WOULD BE RECKLESS AND INAPPROPRIATE FOR RETAIL INVESTORS TO USE, FOUR TWENTY SEVEN’S RESEARCH WHEN MAKING AN INVESTMENT DECISION. IF IN DOUBT YOU SHOULD CONTACT YOUR FINANCIAL OR OTHER PROFESSIONAL ADVISER. FOUR TWENTY SEVEN’S RESEARCH IS NOT INTENDED FOR USE BY ANY PERSON AS A BENCHMARK AS THAT TERM IS DEFINED FOR REGULATORY PURPOSES AND MUST NOT BE USED IN ANY WAY THAT COULD RESULT IN THEM BEING CONSIDERED.
            </p>		
                        
            <p>
                ALL INFORMATION CONTAINED HEREIN IS PROTECTED BY LAW, INCLUDING BUT NOT LIMITED TO, COPYRIGHT LAW, AND NONE OF SUCH INFORMATION MAY BE COPIED OR OTHERWISE REPRODUCED, REPACKAGED, FURTHER TRANSMITTED, TRANSFERRED, DISSEMINATED, REDISTRIBUTED OR RESOLD, OR STORED FOR SUBSEQUENT USE FOR ANY SUCH PURPOSE, IN WHOLE OR IN PART, IN ANY FORM OR MANNER OR BY ANY MEANS WHATSOEVER, BY ANY PERSON WITHOUT FOUR TWENTY SEVEN’S PRIOR WRITTEN CONSENT.	
            </p>		
                        
            <p>
                ALL INFORMATION CONTAINED HEREIN IS OBTAINED BY FOUR TWENTY SEVEN FROM SOURCES BELIEVED BY IT TO BE ACCURATE AND RELIABLE. BECAUSE OF THE POSSIBILITY OF HUMAN OR MECHANICAL ERROR AS WELL AS OTHER FACTORS, HOWEVER, ALL INFORMATION CONTAINED HEREIN IS PROVIDED “AS IS” WITHOUT WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND, INCLUDING AS TO THE ACCURACY, TIMELINESS, COMPLETENESS, MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE. FOUR TWENTY SEVEN IS NOT AN AUDITOR AND CANNOT IN EVERY INSTANCE INDEPENDENTLY VERIFY OR VALIDATE INFORMATION IT RECEIVES.	
            </p>
                        
            <p>
                To the extent permitted by law, Four Twenty Seven and its directors, officers, employees, agents, representatives, licensors and suppliers (together, Four Twenty Seven Parties”) disclaim liability to any person or entity for any (a) indirect, special, consequential, or incidental losses or damages, and (b) direct or compensatory losses or damages caused to any person or entity, including but not limited to by any negligence (but excluding fraud, willful misconduct or any other type of liability that, for the avoidance of doubt, by law cannot be excluded); on the part of, or any contingency within or beyond the control of any Four Twenty Seven Party, arising from or in connection with the information contained herein or the use of or inability to use any such information.
            </p>	
    </div>
</div>
