import { Component, OnInit } from '@angular/core';
import { SIDEBAR_OPTIONS } from './sidebar.constants';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { RedirectService } from '@shared/services/redirect.service';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    public errors: boolean = false;
    public isMinimized: boolean = true;
    public showLayout: boolean = true;
    public showBreadcrumb: boolean = true;
    public minimizeItem: boolean = true;
    public isHubUser: boolean = false;
    public menuItems = SIDEBAR_OPTIONS;
    public layoutType!: string;
    public performEnrollments = 0;
    public performAdmin = false;
    public customLeedLink: string = this.redirect.getHUBURL() + '/projects/project-list-by-type';

    constructor(
        private readonly layoutService: LayoutService,
        private readonly redirect: RedirectService
    ) {}

    ngOnInit(): void {
        this.layoutService.show.subscribe((showLayout: boolean) => {
            this.showLayout = showLayout;
        });

        this.layoutService.showBreadcrumb.subscribe((showBreadcrumb: boolean) => {
            this.showBreadcrumb = showBreadcrumb;
        });

        this.layoutService.layoutType.subscribe((layoutType: string) => {
            this.layoutType = layoutType;
        });
    }

    routeTo(routeLink: string): void {
        this.redirect.redirectToHub(routeLink);
    }

    trackByIndex(index: number, _el: any): number {
        return index;
    }

    redirectPerform(): string {
        // add logic here for leed

        let url = this.redirect.getHUBURL() + '/projects/perform/landing';
        return url;
    }
}
