import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentiaction.service';
import { AlertService } from '@shared/services/alert.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(
        private readonly authorizationService: AuthenticationService,
        private readonly alertService: AlertService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const SUBS_KEY = this.authorizationService.getSubscriptionKey();
        const JWT_TOKEN = this.authorizationService.getJWTToken();

        const modifiedRequest = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + String(JWT_TOKEN),
                'Ocp-Apim-Subscription-Key': SUBS_KEY
            }
        });

        return next.handle(modifiedRequest).pipe(
            catchError(error => {
                if (error.status == 401) {
                    this.authorizationService.logout();
                    this.authorizationService.login();
                } else if (error.status == 403 ) {
                    localStorage.removeItem('jwt');
                    localStorage.removeItem('auth0');
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('accessToken');
                    this.authorizationService.login();
                } else if (error.status == 404) {
                    this.alertService.alert('error', "404: Not found", 5);
                }

                return next.handle(request);
            })
        );
    }
}
