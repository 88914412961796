import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { API } from '../../legacy-services/api.service';
import { AppData } from '../../legacy-services/appdata.service';
import { Config } from 'src/app/core/config';
import { GlobalService } from '../../legacy-services/global.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@shared/services/alert.service';

@Component({
    selector: 'totalscore_leed-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/totalscore_leed.html',
    imports: [CommonModule],
})
export class totalscore_leed implements OnInit {
    constructor(
        public global: GlobalService,
        public appData: AppData,
    ) {}

    ngOnInit(): void {

    }
}

@Component({
    selector: 'totalscore-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/totalscore.html',
    imports: [CommonModule],
})
export class totalscore implements OnInit {
    constructor(
        public global: GlobalService,
        public appData: AppData,
    ) {}

    ngOnInit(): void {

    }
}

@Component({
    selector: 'team_roles_help-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/team_roles_help.html',
    imports: [CommonModule],
})
export class team_roles_help implements OnInit {
    constructor(
        public global: GlobalService,
        public appData: AppData,
    ) {}

    ngOnInit(): void {

    }
}

@Component({
    selector: 'totalscore_city-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/totalscore_city.html',
    imports: [CommonModule],
})
export class totalscore_city implements OnInit {
    config = Config;
    constructor(
        public global: GlobalService,
        public appData: AppData,
    ) {}

    ngOnInit(): void {

    }
}

@Component({
    selector: 'arc_pro_policy-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/arc_pro_policy.html',
    imports: [CommonModule],
})
export class arc_pro_policy implements OnInit {
    config = Config;
    @Input() type: string = '';

    constructor(
        public global: GlobalService,
        public appData: AppData,
    ) {}

    ngOnInit(): void {

    }
}

@Component({
    selector: 'sidebar-app-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/sidebar-app.html',
    imports: [CommonModule],
})
export class VendorAppHelp implements OnInit {
    @Input() type: string = '';
    checking_integration: boolean = false;

    constructor(
        public global: GlobalService,
        public appData: AppData,
        private readonly api: API,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {

    }

    cancel(): void {
        // this.activeModal.close()
    }

    confirmMeasurablIntegration(): void {
        // (<any>$('.confirm_measurabl_integration')).modal('show');
    }

    addMeasurablIntegration(): void {
        const data = { partner_details: 3 };
        this.api.post('/assets/property:' + String(this.appData.get('leed_id')) + '/partners/integration/', data).subscribe(
            // success handler
            response => {
                this.confirmMeasurablIntegration();
                this.cancel();
            },
            // error handler
            response => {
                this.alertService.alert('error', 'Something went wrong during integration.', 5);
            }
        );
    };

    goToPayment(project: any, integration: any): void {
        // $('.trial-banner').slideUp();
        this.appData.set('integration', integration);
        this.appData.set('payment_init', this.appData.get('current_app'));
        if (integration !== undefined) {
            this.checking_integration = true;
            Config.app_loader = true;
            this.api.get('/assets/property:' + String(project.leed_id) + '/partners/integration/').subscribe(
                response => {
                    Config.app_loader = false;
                    this.checking_integration = false;
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].partner_details === 3) {
                            // (<any>$('.integration_invalid')).modal('show');
                            this.cancel();

                            return;
                        }
                    }

                    this.addMeasurablIntegration();
                    this.cancel();
                },
                errpr => {
                    Config.app_loader = false;
                    this.checking_integration = false;
                }
            );
        } else {
            this.global.goToPayment(this.appData.get('buildingData'), { soreference: 'MEASURABL MONTHLY SUBSCRIPTION', app_name: 'measurabl' });
            this.cancel();
        }
    };
}

@Component({
    selector: 'cert_details-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/cert_details.html',
    imports: [CommonModule],
})
export class CertDetails implements OnInit {
    @Input() project: any = {};
    certification: any = {};
    expire: any = undefined;
    loading: boolean = true;

    constructor(
        // public activeModal: NgbActiveModal,
        public global: GlobalService,
        public appData: AppData,
        private readonly api: API
    ) {}

    ngOnInit(): void {
        this.api.get('/assets/property:' + String(this.project.leed_id) + '/certifications/').subscribe(
            response => {
                this.loading = false;
                if (response.certificates.length > 0) {
                    const certs = response.certificates; let certification = response.certificates[0];
                    for (let i = 0; i < certs.length; i++) {
                        if (new Date(certs[i].date_certified) > new Date(certification.date_certified)) {
                            certification = certs[i];
                        }
                    }

                    this.certification = certification;
                    this.expire = this.global.dateFormat(this.certification.certification_expire, true);
                }
            },
            () => {
                this.loading = false;
            }
        );
    }

    cancel(): void {
        // this.activeModal.close()
    }
}

@Component({
    selector: 'na_help-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/na_help.html',
    imports: [CommonModule],
})
export class na_help implements OnInit {
    constructor(
        public global: GlobalService,
        public appData: AppData,
    ) {}

    ngOnInit(): void {

    }

    cancel(): void {
        // this.activeModal.close()
    }
}

@Component({
    selector: 'parksmart-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/parksmart.html',
    imports: [CommonModule],
})
export class parksmart implements OnInit {
    constructor(
        public global: GlobalService,
        public appData: AppData,
    ) {}

    ngOnInit(): void {

    }

    cancel(): void {
        // this.activeModal.close()
    }
}

@Component({
    selector: 'perf-cert-help-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/scoring_factors_correct.html',
    imports: [CommonModule],
})
export class PerfCertHelp implements OnInit {
    @Input() data_coverage: any = {
        performance: {},
        improvement: {}
    };

    @Input() total_errors: any;

    constructor(
        private readonly dialog: MatDialogRef<PerfCertHelp>,
    ) {}

    ngOnInit(): void {

    }

    cancel() {
        this.dialog.close({ event: 'Cancel' });
    };
}

@Component({
    selector: 'cr_disclaimer-help-content',
    standalone: true,
    templateUrl: '../../../../assets/templates/helpbox/cr_disclaimer.html',
    imports: [CommonModule],
})
export class CRDisclaimer implements OnInit {
    @Input() data_coverage: any = {
        performance: {},
        improvement: {}
    };

    @Input() total_errors: any;

    constructor(
        private readonly dialog: MatDialogRef<CRDisclaimer>,
    ) {}

    ngOnInit(): void {

    }

    cancel() {
        this.dialog.close({ event: 'Cancel' });
    };
}
