import { Component } from '@angular/core';
import { GlobalService } from '@shared/legacy-services/global.service';
import { RedirectService } from '@shared/services/redirect.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent {
    constructor(
        private global: GlobalService,
        private readonly redirect: RedirectService
    ) {}
}
