<ul
    class="breadcrumb">
    <li
        class="breadcrumb-item"
        *ngFor="let breadcrumb of breadcrumbs$ | async">
        <a
            (click)="redirectTo(breadcrumb.url)">
            {{ breadcrumb.label }}
        </a>
    </li>
</ul>
