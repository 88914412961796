import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AlertService {

    private config = new MatSnackBarConfig();
    
    constructor(
        private readonly snackBar: MatSnackBar
    ) {}

    alert(
        type: 'success' | 'error' | 'info',
        message: string,
        duration: 5 //in seconds
    ) {
        this.config.duration = duration * 1000;
        this.config.panelClass = ['usgbc-snack-bar', type];
        this.snackBar.open(message, '', this.config);
    }
}
