<div id="arc_help">
    <div *ngIf="type != 'Arc Performance Certificate'" class="header_container block floatl mb20 w100p">
        <h4 class="modal-title floatl">Arc Essentials cancellation policy</h4>
        <span class="arc--closModalIcon" ></span>
    </div>
    
    <div *ngIf="type != 'Arc Performance Certificate'" class="p2535">
        <p class="fw-500 align-center">
            Subscription Agreement & Cancellation Policy
        </p><br>

        <p>
            This policy addresses the rules and guidelines around your {{config.arc_pro_label}} annual subscription service with Green Business Certification Inc. (“GBCI”). This service allows the user to utilize upgraded tools, features and reports and receive benefits that are not provided as part of the Arc for All offering.
        </p><br>

        <p class="fw-500">Purchase</p>
        <p>
            By clicking the Opt-in box and completing the payment information, you have finalized your purchase and authorized GBCI to charge your credit card per the subscription agreement. A receipt will be emailed to you and will be available for viewing and download in the Billing section of the platform. A copy of this Subscription Agreement and Cancellation Policy, alerting you to the guidelines required to cancel your contract, will be available on the subscription tab within the Billing section. This is where you will have the ability to print or download the policy.
        </p><br>


        <p class="fw-500">Payment</p>
        <p>
            If you’ve selected the annual payment option, we will charge the credit card you provided for your initial purchase. That same credit card will be charged for your renewal purchase on the same date, one year later, for the amount of the service in the posted fee table as of that date.
        </p><br>
        <p>
            If you’ve selected the monthly payment option, we will charge the credit card you provided for your initial monthly fee. That same credit card will be charged on the same day of every month for the remainder of the subscription period. The monthly fee will be in line with the posted fee table as of that date.
        </p><br><br>

        <p class="fw-500">Cancellation</p>
        <p>
            You may cancel for any reason, without penalty, within 48 hours of selecting the Opt-in box and making payment for our service in order to receive a full refund as long as no benefits have been used. No refund will be issued for cancellations beyond 48 hours, or where benefits have been used.
        </p><br>
        <p>
            If you wish to cancel your annual subscription service, go to the Billing section and select the Cancel button. 
        </p><br>
        <p>
            If you cancel your subscription agreement, all user access associated with this agreement will be blocked from utilizing the tools, features and reports available in the upgraded service at the end of the period covered by your most recent payment.
        </p><br>
    </div>

    <div *ngIf="type == 'Arc Performance Certificate'" class="header_container block floatl mb20 w100p">
        <h4 class="modal-title floatl">Arc Performance Certificate cancellation policy</h4>
        <span class="arc--closModalIcon"></span>
    </div>

    <div *ngIf="type == 'Arc Performance Certificate'" class="p2535">
        <p class="fw-500 align-center">
            Subscription Agreement & Cancellation Policy
        </p><br>

        <p>
            This policy addresses the rules and guidelines around your Performance Certificate service with Green Business Certification Inc. (“GBCI”). This service allows your project to track and score performance for Energy, Water, Waste, Transportation and/or Human Experience as individual categories.
        </p><br>

        <p class="fw-500">Purchase</p>
        <p>
            By clicking the Opt-in box and completing the payment information, you have finalized your purchase and authorized GBCI to charge your credit card per the subscription agreement. A receipt will be emailed to you and will be available for viewing in the Billing section of the platform. A copy of this Subscription Agreement and Cancellation Policy, alerting you to the guidelines required to cancel your contract, will be available on the Subscription tab within the Arc Performance Certificates section of the platform. This is where you will have the ability to print or download the policy.
        </p><br>


        <p class="fw-500">Payment</p>
        <p>
            If you’ve selected the annual payment option, we will charge the credit card you provided for your initial purchase. That same credit card will be charged for your renewal purchase on the same date, one year later, for the amount of the service in the posted fee table as of that date.
        </p><br>

        <p class="fw-500">Cancellation</p>
        <p>
            You may cancel for any reason, without penalty, within 48 hours of selecting the Opt-in box and making payment for our service in order to receive a full refund as long as no benefits have been used. No refund will be issued for cancellations beyond 48 hours, or where benefits have been used.
        </p><br>
        <p>
            If you wish to cancel your annual subscription service, go to your Subscription tab in the Arc Performance Certificates section and select the Cancel button.
        </p><br>
        <p>
            If you cancel your subscription agreement, all user access associated with this agreement will be blocked from utilizing the tools, features and reports available in the upgraded service at the end of the period covered by your most recent payment.
        </p><br>
    </div>
</div>