export const IMPORVEMENT_SCORE = {
    energy: [
        [
            {
                title: 'Site energy intensity',
                subtitle: 'unit/perUnit',
                unit: 'data_unit',
                perUnit: 'intensity_unit'
            },
            {
                score: 'baseline_site_energy',
                quality: 'baseline_site_energy_quality'
            },
            {
                score: 'performance_site_energy',
                quality: 'performance_site_energy_quality'
            },
            {
                reduction: 'reduction_in_site_energy'
            }
        ],
        [
            {
                title: 'Source energy intensity',
                subtitle: 'unit/perUnit',
                unit: 'data_unit',
                perUnit: 'intensity_unit'
            },
            {
                score: 'baseline_source_energy',
                quality: 'baseline_source_energy_quality'

            },
            {
                score: 'performance_source_energy',
                quality: 'performance_source_energy_quality'

            },
            {
                reduction: 'reductionIn_source_energy'
            }
        ],
        [
            {
                title: 'Energy cost intensity',
                subtitle: 'cost (USD)/perUnit',
                perUnit: 'intensity_unit'
            },
            {
                score: 'baseline_cost',
                quality: 'baseline_cost_quality'
            },
            {
                score: 'performance_cost',
                quality: 'performance_cost_quality'

            },
            {
                reduction: 'reduction_in_cost'
            }
        ],
        [
            {
                title: 'Gross area',
                subtitle: 'perUnit',
                perUnit: 'intensity_unit'

            },
            {
                score: 'baseline_area',
            },
            {
                score: 'performance_area',
            },
            {
                reduction: 'reduction_in_area'
            }
        ]
    ],
    water: [
        [
            {
                title: 'Estimated water',
                subtitle: 'cost (unit)/perUnit',
                unit: 'data_unit',
                perUnit: 'intensity_unit'

            },
            {
                score: 'baseline_consumption',
                quality: 'baseline_consumption_quality'
            },
            {
                score: 'performance_consumption',
                quality: 'performance_consumption_quality'
            },
            {
                reduction: 'reductionIn_consumption'
            }
        ],
        [
            {
                title: 'Gross area',
                subtitle: 'perUnit',
                perUnit: 'intensity_unit'
            },
            {
                score: 'baseline_area',
            },
            {
                score: 'performance_area',
            },
            {
                reduction: 'reduction_in_area'
            }
        ]
    ],
    waste: [
        [
            {
                title: 'Estimated waste generated',
                subtitle: 'unit/occupant/day',
                unit: 'data_unit',
            },
            {
                score: 'baseline_generated',
                quality: 'baseline_generated_quality'
            },
            {
                score: 'performance_generated',
                quality: 'performance_generated_quality'
            },
            {
                reduction: 'reduction_in_generated'
            }
        ],
        [
            {
                title: 'Estimated waste diverted',
                subtitle: 'unit/occupant/day',
                unit: 'data_unit'
            },
            {
                score: 'baseline_diverted',
                quality: 'baseline_diverted_quality'
            },
            {
                score: 'performance_diverted',
                quality: 'performance_diverted_quality'
            },
            {
                reduction: 'reduction_in_diverted',
                invertquality: true
            }
        ],
        [
            {
                title: 'Occupants',
                subtitle: 'people'
            },
            {
                score: 'baseline_occupants',
            },
            {
                score: 'performance_occupants',
            },
            {
                reduction: 'reduction_in_occupants'
            }
        ],
    ],
    transportation: [
        [
            {
                title: 'Estimated Avg. CO2E',
                subtitle: 'mtCO2e'
            },
            {
                score: 'baseline_emission',
            },
            {
                score: 'performance_emission',
            },
            {
                reduction: 'reduction_in_emission'
            }
        ],
        [
            {
                title: 'Occupants',
                subtitle: 'people'
            },
            {
                score: 'baseline_occupants',
            },
            {
                score: 'performance_occupants',
            },
            {
                reduction: 'reduction_in_occupants'
            }
        ],
    ],
    human_experience: [
        [
            {
                title: 'Estimated occupant satisfaction',
                subtitle: 'Occupant average'
            },
            {
                score: 'baseline_satisfaction',
            },
            {
                score: 'performance_satisfaction',
            },
            {
                reduction: 'reduction_in_satisfaction'
            }
        ],
        [
            {
                title: 'Occupants',
                subtitle: 'people'
            },
            {
                score: 'baseline_occupants',
            },
            {
                score: 'performance_occupants',
            },
            {
                reduction: 'reduction_in_occupants'
            }
        ],
    ],
};

export const DATE_FORMAT = 'dd/MM/YYYY';
