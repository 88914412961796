import { Injectable } from '@angular/core';
import { Config } from 'src/app/core/config';
import { AppData } from './appdata.service';
import { MatDialog } from '@angular/material/dialog';
import * as SideBar from '../legacy-components/modal/template-wrapper';
import { API } from './api.service';

import 'moment-timezone';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class GlobalService {
    constructor(
        public appData: AppData,
        private readonly dialog: MatDialog,
        private readonly api: API,
        private readonly router: Router,
    ) {}

    showMaintenanceModal(): void {

    }

    goToExternalLink(link: string): void {

    }

    openPolicy(type?: string): void {
        this.dialog.open(SideBar.arc_pro_policy, {
            width: '834px',
            minHeight: '100vh',
            panelClass: 'right-side-drawer',
            position: {
                top: '0',
                right: '0',
            },
        });
    };

    goBack() {

    }

    openEmail(email: string) {
        window.open('mailto:' + email, '_self');
    }

    getCountryList() {
        return this.doSorting(Config.countries.countries);
    }

    fetchCountryStates() {
        return this.api.get(
            '/country/states/'
        );
    };

    doSorting = function (obj: any) {
        let newList = [];

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                newList.push({ code: key, name: obj[key] });
            }
        }

        newList = newList.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });

        return newList;
    };

    getStateList(country: string) {
        return this.doSorting(Config.countries.divisions[country]);
    }

    goToApp(path: string, queryParams?: any): void {
        if (queryParams != undefined) {
            this.router.navigate([path], { state: queryParams });
        } else {
            this.router.navigate([path]);
        }

        window.scrollTo(
            {
                top: 0,
                behavior: 'smooth',
            });
    }

    number_notation(num: number, digits: number) {
        if (isNaN(num)) {
            num = 0;
        }

        const number_set =
		[
		    { value: 1, symbol: '' },
		    { value: 1E3, symbol: 'k' },
		    { value: 1E6, symbol: 'M' },
		    { value: 1E9, symbol: 'G' },
		    { value: 1E12, symbol: 'T' },
		    { value: 1E15, symbol: 'P' },
		    { value: 1E18, symbol: 'E' }
		];
        let i;
        for (i = number_set.length - 1; i > 0; i--) {
            if (num >= number_set[i].value) {
                break;
            }
        }

        return (num / number_set[i].value).toFixed(digits) + number_set[i].symbol;
    }

    getBrowser(): any {
        const ua = navigator.userAgent.toLowerCase();
        const ie = ua.includes('msie ') || ua.includes('trident/') || ua.includes('edge');
        let chrome = false;
        let safari = false;

        if (ua.includes('safari')) {
            if (ua.includes('chrome')) {
                chrome = true;
            } else {
                safari = true;
            }
        }

        return { ie, chrome, safari };
    }

    getURLFromParam(sParam: string, url?: string): any {
        if (url == undefined) {
            const sPageURL = window.location.search.substring(1);
            const sURLVariables = sPageURL.split('&');
            for (let i = 0; i < sURLVariables.length; i++) {
                const sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] == sParam) {
                	return decodeURI(sParameterName[1]);
                }
            }
        } else {
            const params: any = new Proxy(new URLSearchParams(window.location.search),
                {
                    get: (searchParams, prop) => searchParams.get(prop as any),
                });

            return params[sParam];
        }
    }

    showHelpText(type: string): void {
        if (type === 'total_leed_arc') {
            if (parseFloat(this.appData.get('buildingData').score_code_version.name) >= 3) {
                type = 'total_leed';
            } else {
                type = 'total_arc';
            }
        }

        let modalRef: any;
        if (type === 'total_leed') {
            modalRef = SideBar.totalscore_leed;
        } else if (type === 'total_arc') {
            modalRef = SideBar.totalscore;
        } else if (type === 'total_leed_arc_city') {
            modalRef = SideBar.totalscore_city;
        } else if (type === 'team_roles_help') {
            modalRef = SideBar.team_roles_help;
        } else if (type === 'na_help') {
            modalRef = SideBar.na_help;
        } else if (type === 'parksmart') {
            modalRef = SideBar.parksmart;
        } else if (type === 'perf-cert-help') {
            modalRef = SideBar.PerfCertHelp;
        } else if (type === 'cr_disclaimer') {
            modalRef = SideBar.CRDisclaimer;
        }

        this.dialog.open(modalRef, {
            width: '834px',
            minHeight: '100vh',
            panelClass: 'right-side-drawer',
            position: {
                top: '0',
                right: '0',
            },
        });
    };

    goToPayment(project: any, integration?: any): void {
        if (Config.maintenance_banner) {
            this.showMaintenanceModal();

            return;
        }

        // $('.trial-banner').slideUp();
        this.appData.set('leed_id', project.leed_id);
        this.appData.set('buildingData', project);
        this.appData.set('integration', integration);
        this.appData.set('payment_init', this.appData.get('current_app'));
        this.goToApp('project-payment',
            {
                leed_id: project.leed_id,
                app_name: 'project-payment',
                init: this.appData.get('current_app'),
                integration
            });
    }

    upgrade(mode: string, leedId: any, subscriptions?: any): void {
        this.goToApp(
            'properties/' + leedId + '/performance-certificates/purchase-subscription', {
                subscriptions
            }
        );
    }

    formatNumberCommas(num: any, dec?: any, showNA?: boolean): any {
        if (dec === undefined) {
            dec = 2;
        }

        if ((num === null || num === undefined) && showNA) {
            return 'NA';
        }

        if (isNaN(parseInt(num))) {
            num = '0';
        }

        let threshold: any = '0.'; let n = 0;
        while (num > 0 && n < dec - 1) {
            threshold += '0';
            n += 1;
        }

        threshold = parseFloat(threshold + '1');
        if (num > 0 && num < threshold) {
            return num.toExponential(dec);
        }

        let zeroes = '.';
        for (let i = 0; i < dec; i++) {
            zeroes += '0';
        }

        num = parseFloat(num).toFixed(dec).replace(zeroes, '');
        const x = num.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? '.' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }

        return x1 + x2;
    }

    learnMore(mode: string, leed_id?: number): void {
        if (leed_id === undefined && Config.project_apps.includes(this.appData.get('current_app'))) {
            leed_id = this.appData.get('leed_id');
        }

        this.goToApp('learn-more',
            { mode, leed_id }
        );
    }

    dateFormat(dateTime?: string, none?: boolean, format?: string): string {
        if (dateTime === undefined) {
            if (none) {
                return '-';
            }

            return moment(new Date()).format('MMM DD, YYYY');
        } else {
            if (format === undefined) {
                format = 'YYYY-MM-DD';
            }

            return moment.tz(dateTime, moment.tz.guess()).format('MMM DD, YYYY');
        }
    }

    addCommas(nStr: string | number, decimals?: number): any {

        if(decimals != undefined) {
            nStr = this.formatNumber(nStr, decimals, false)
        }

        nStr = String(nStr).replace(/,/g, '');
        if (nStr === null) {
            return;
        }

        nStr += '';
        const x = nStr.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? '.' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }

        return x1 + x2;
    }

    openLink(url: string, newTab?: any): void {
        window.open(url, newTab);
    }

    openMail(email: string): void {
        window.open('mailto:' + email, '_self');
    }

    formatNumber(number: any, decimals: number, showNA?: boolean): string {

        if (
            ((number === null || number === undefined) && showNA) ||
            number == 'NA'
        ) {
            return 'NA';
        }

        if (isNaN(number)) {
            number = 0;
        }

        let amt: any = parseFloat(String(number));
        amt = amt.toFixed(decimals);
        if (isNaN(amt) || String(amt) === 'Infinity' || String(amt) === '-Infinity') {
            return '0';
        } else {
            return amt;
        }
    };

    isNA(number: any): boolean {
        return !!((number === null || number === undefined || isNaN(parseInt(number))));
    }

    last12Month() {
        const end = moment(new Date());
        const start = end.clone().subtract(1, 'year').add(1, 'day');

        return [start, end];
    };

    changeDateFormat(date: any, format: string) {
        if (date instanceof moment) {
            return (date as any).format(format);
        } else {
            return moment(date).format(format);
        }
    }

    getFileName(uploaded_file: any) {
        try {
            return uploaded_file.doc_key.split('/')[uploaded_file.doc_key.split('/').length - 1];
        } catch (e) {
            return '';
        }
    }

    anchorDownload(name: string, url: string) {
        const a = document.createElement('a');
        const linkText = document.createTextNode(name);
        a.appendChild(linkText);
        a.title = name;
        a.href = url;
        a.target = '_blank';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
    };

    dateTimeFormat(dateTime?: string) {
        if (dateTime == undefined) {
            return moment(new Date()).format('MMM DD, YYYY [at] hh:mm A');
        } else {
            return moment.tz(dateTime, moment.tz.guess()).format('MMM DD, YYYY [at] hh:mm A');
        }
    };

    downloadPDF(name: string) {
        window.open('../../../assets/pdf' + name);
    };

    downloadXLS(name: string) {
        const a = document.createElement('a');
        const linkText = document.createTextNode(name);
        a.appendChild(linkText);
        a.title = name;
        a.href = '../../../assets/excel-sheets/' + name;
        document.body.appendChild(a);
        a.click();
    }

    getState(state:string, country:string, NA?: boolean)
	{
		if(Config.countries == null || Config.countries.divisions[country] == undefined)
		{
			return NA ? 'Not Available' : state;
		}
		return Config.countries.divisions[country][state];
	};

    getCountry(country:string)
	{
		if(Config.countries == null)
		{
			return country;
		}
		return Config.countries.countries[country];
	};

    copy(obj: any)
	{
		return JSON.parse(JSON.stringify(obj));
	}

    generateString(length: number)
	{
		let result = '', characters: string ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';;
		const charactersLength = characters.length;
		for ( let i = 0; i < length; i++ )
		{
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

    downloadFile(uploaded_file: any)
	{
		var url    = Config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + uploaded_file["id"] + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
		var name   = uploaded_file.doc_key.split('/')[ uploaded_file.doc_key.split('/').length - 1 ];
		var a = document.createElement('a');
		var linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);
		a.style.display = 'none';
		a.click();
	}
}
