import { environment } from 'src/environments/environment';

export const SIDEBAR_OPTIONS = [
    {
        "id": "1",
        "label": "Dashboard",
        route: environment.HUB_URL + '/home',
        "icon": "grid_view",
        "img": "",
        "position": "top"
    },
    {
        "id": "2",
        "label": "Projects",
        route: environment.HUB_URL + '/projects',
        "icon": "assignment",
        "description": "A project is the program or rating system that applies to a property or portfolio.  A property or portfolio can have multiple projects.",
        "img": "",
        "position": "top",
        "tourLabel": "Projects"
    },
    {
        "id": "3",
        "label": "Properties",
        route: environment.HUB_URL + '/properties',
        "icon": "apartment_outline",
        "description": "A property is a physical space or location, such as a building, a portion of a building, a city, neighborhood, or campus.",
        "img": "",
        "position": "top",
        "tourLabel": "Properties"
    },
    {
        "id": "4",
        "label": "Portfolios",
        route: environment.HUB_URL + '/portfolios',
        "icon": "work_outline",
        "description": "A portfolio is a user-defined grouping of properties.",
        "img": "",
        "position": "top",
        "tourLabel": "Portfolios"
    },
    {
        "id": "5",
        "label": "PERFORM",
        route: environment.HUB_URL + '/projects/perform/landing',
        "icon": "",
        "description": "These navigation options are provided to direct you to the PERFORM and LEED workspaces",
        "img": "custom-p-menu",
        "position": "bottom",
        "tourLabel": "PERFORM & LEED"
    },
    {
        "id": "6",
        "label": "LEED",
        route: environment.HUB_URL + '/projects/project-list-by-type',
        "icon": "",
        "img": "custom-l-menu",
        "position": "bottom"
    }
];
