<div id="arc_help">

    <div class="header_container">
      <span class="title">How do I correct my scoring factors?</span>
      <span (click)="cancel()" class="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="black"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
      </span>
    </div>
  
    <div class="p2535">
      <p>
        Arc Certificates are subject to a battery of automated tests to evaluate the integrity of the underlying performance information. To be valid, each project must pass the following five tests. Data must be:
      </p>
      
      <p>
      <h4>Complete</h4>
      <p>
        Information is provided for the entire performance period (12 months for Performance Score and 24 months for Improvement Score
      </p>
      
      <p class="fw500">
        How to Fix This:
      </p>
      <p>
        • Change your performance period dates.
      </p>
      
      
      <p>
      <h4>Timely</h4>
      <p>
        Data must be recent. For Arc Performance Certificates, this means that data must be submitted within 12 months (ie. the end of the performance period is a maximum of 12 months from the current date)
      </p>
      
      <p class="fw500">
        How to Fix This:
      </p>
      <p>
        • Update your data
      </p>
      
      
      <p>
      <h4>Check for Outliers</h4>
      <p>
        Information falls within a reasonable range based on the project type and circumstances. This is Initially defined as values less than 99 percent of the maximum value observed in the reference set and more than 1 percent of minimum value observed in the reference set.
      </p>
      
      <p class="fw500">
        How to Fix This:
      </p>
      <p>
        • Update your data
      </p>
      
      
    </div>