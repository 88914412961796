<mat-toolbar class="main-toolbar">
    <span class="toolbar-item-spacer"></span>
    <button
        class="account"
        mat-icon-button
        matTooltip="My Account"
        [matMenuTriggerFor]="userAccountMenu">
        {{ userName | initials }}
    </button>
    <mat-menu
        #userAccountMenu
        [overlapTrigger]="false"
        yPosition="below">
        <button
            mat-menu-item
            (click)="logout()">
            <mat-icon class="material-icons-outlined material-symbols-outlined">exit_to_app</mat-icon>
            <span>Log out</span>
        </button>
        <a
            mat-menu-item
            [href]="changePasswordUrl">
            <mat-icon class="material-icons-outlined material-symbols-outlined">key</mat-icon>
            <span>Change password</span>
        </a>
        <a
            mat-menu-item
            [routerLink]="'user/billing'">
            <mat-icon class="material-icons-outlined material-symbols-outlined">receipt_long</mat-icon>
            <span>Billing</span>
        </a>
    </mat-menu>
</mat-toolbar>