import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AppData } from '@shared/legacy-services/appdata.service';
import { Observable, map, of, tap } from 'rxjs';
import { API } from 'src/app/shared/legacy-services/api.service';
import { environment } from 'src/environments/environment';
import { Config } from '../../config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public auth0Token!: string | null;
    private jwtToken: string | null = null;

    constructor(
        private readonly api: API,
        private readonly auth0Service: AuthService,
        private readonly appData: AppData
    ) {}

    getJWTToken(): string | null {
        return this.jwtToken;
    }

    setJWTToken(jwtToken: string | null): void {
        this.jwtToken = jwtToken;
    }

    getSubscriptionKey(): string {
        return environment.subscription_key;
    }

    isLoggedIn(): Observable<boolean> {
        return localStorage.getItem('accessToken')
            ? of(true)
            : this.auth0Service.isAuthenticated$.pipe(
                map((auth0State) => {
                    return auth0State || !!(localStorage.getItem('user') && localStorage.getItem('accessToken'));
                })
            );
    }

    getAuth0Token(): Observable < any > {
        return localStorage.getItem('accessToken')
            ? of(localStorage.getItem('accessToken'))
            : this.auth0Service.getAccessTokenSilently(
                {
                    detailedResponse: true
                }).pipe(
                map(data => data.id_token),
                tap(token => {
                    localStorage.setItem('accessToken', token);
                })
            );
    };

    getJWT(token?: string | null): Observable<any> {
        return localStorage.getItem('jwtToken')
            ? of(localStorage.getItem('jwtToken'))
            : this.api.post('/auth/oauth0/jwt-verifier/', { token }).pipe(
                tap(data => {
                    localStorage.setItem('jwtToken', data.authorization_token);
                    localStorage.setItem('is_admin', data.is_admin);
                    localStorage.setItem('user_id', data.user.username);
                    
                    this.appData.setLocal('user', data.user);
                    this.appData.setLocal('auth0', data);

                    this.appData.set('token', data.authorization_token);
                    this.appData.set('user_role', data.user_role);
                    this.appData.set('user', data.user);
                    this.appData.set('user_id', data.user.username);
                    this.appData.set('user_firstname', data.user.first_name);
                    this.appData.set('user_lastname', data.user.last_name);
                }),
                map(token => token.authorization_token)
            );
    }

    logout(): Observable<void> {
        localStorage.removeItem('jwt');
        localStorage.removeItem('auth0');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('accessToken');

        return this.auth0Service.logout();
    }

    login(): Observable<void> {
        return this.auth0Service.loginWithRedirect({
            appState: { target: window.location.pathname }
        });
    }

    getUSGBCUserProfile() {
		return this.api.get('/auth/usgbc/userprofile/');
	}
}
