import { Injectable } from '@angular/core';

import { AppData } from './appdata.service';
import { API } from './api.service';
import { Observable, of, tap } from 'rxjs';

import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class Projects {
    constructor(
        private readonly appData: AppData,
        private readonly api: API
    ) {}

    getAssetDetails(leed_id: string | number): Observable<any> {
        return this.api.get(
            '/assets/property:' + leed_id + '/'
        ).pipe(
            tap(projectInfo => {
                projectInfo.performance_end_date = projectInfo.performance_end_date == null ? moment(new Date()).format('YYYY-MM-DD') : projectInfo.performance_end_date;
                projectInfo.performance_start_date = moment(projectInfo.performance_end_date).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
                this.appData.set('buildingData', projectInfo);
            })
        );
    }

    getProjects(page: number | null, page_size: number | null, ordering: string | null, project_type: string | null, filters: string | null, type?: string) {
        page = typeof page !== null ? page : 1;
        page_size = typeof page_size !== null ? page_size : 10;
        ordering = typeof ordering !== null ? ordering : '';
        filters = typeof filters !== null ? filters : '';

        let url = '/assets/';

        if (project_type === null) {
            url = '/assets/?page=' + page + '&page_size=' + page_size + '&project_type=' + this.appData.get('project_type');
        } else {
            url = '/assets/?page=' + page + '&page_size=' + page_size + '&project_type=' + project_type.replace('schools', 'school');
        }

        if (project_type === 'all') {
            url = '/assets/?page=' + page + '&page_size=' + page_size;
        }

        if (project_type === 'certified') {
            url += '&certification=all';
            url = url.replace('&project_type=certified', '');
        }

        if (ordering != null && ordering != undefined && ordering.length > 0) {
            url += '&ordering=' + ordering;
        }

        if (filters != null) {
            url += filters;
        }

        if (type === 'teams_list') {
            url = url.replace('project_type=building', 'project_type=building,school&admin=true');
        }

        return this.api.get(url);
    }

    getActionList(project_type: string, key?: string) {
        let url;
        if (project_type === 'parksmart') {
            url = '/assets/property:' + this.appData.get('leed_id') + '/measures/?page_size=100';
        } else if ((project_type === 'city' || project_type === 'community') && key != undefined) {
            url = '/assets/property:' + this.appData.get('leed_id') + '/measures/?page_size=100&key=' + key;
        } else {
            url = '/assets/property:' + this.appData.get('leed_id') + '/actions/';
        }

        return this.api.get(
            url
        );
    }

    getPortfolios() {
        return this.api.get(
            '/portfolios/?page_size=50'
        );
    }

    getBillingDetails() {
        return this.api.get(
            '/assets/property:' + this.appData.get('leed_id') + '/payments/order/'
        );
    }

    getCcAvenuePayUrl(order_id: any) {
        return this.api.get(
            '/assets/property:' + this.appData.get('leed_id') + '/payments/ccavenue/' + order_id + '/'
        );
    }

    getProjectDocuments() {
        return this.api.get('/assets/property:' + this.appData.get('leed_id') + '/documents/');
    }

    getS3DocumentsForCategory(doc_category: string) {
        return this.api.get('/assets/property:' + this.appData.get('leed_id') + '/s3documents/?doc_category=' + doc_category);
    }

    getCreditUploads(credit_short_id: string, credit_id: string) {
        return this.api.get('/assets/property:' + this.appData.get('leed_id') + '/actions/ID:' + credit_id + '/uploads/');
    }

    getSchoolDetails(page_number: any, page_size: any, order: any, category: any, query: any) {
        order = typeof order !== 'undefined' ? order : false;
        category = typeof category !== 'undefined' ? category : false;
        page_size = typeof page_size !== 'undefined' ? page_size : 10;
        page_number = typeof page_number !== 'undefined' ? page_number : 1;
        query = typeof query !== 'undefined' ? query : '';
        let get_school_details_url = '/assets/unclaimedschools/';

        if (order && category && page_size) {
            get_school_details_url += '?order=' + order + '&category=' + category + '&page_size=' + page_size;
        } else if (page_size) {
            get_school_details_url += '?page_size=' + page_size;
        } else if (order) {
            get_school_details_url += '?order=' + order;
        }

        if (page_number) {
            get_school_details_url += '&page=' + page_number;
        }

        if (query) {
            get_school_details_url += '&q=' + query;
        }

        return this.api.get(get_school_details_url);
    }
}
