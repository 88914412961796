import { Component, Input } from '@angular/core';

@Component({
    selector: 'usgbc-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})

export class NotificationComponent {
    @Input() quality: 'success' | 'error' | 'neutral' | 'info' | 'warning' = 'neutral';
}
