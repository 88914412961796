import { Injectable } from '@angular/core';
import { API } from './api.service';

@Injectable()
export class ValidationService {
    regex: any;

    constructor(
        private readonly api: API
    ) {
        try {
            this.regex = {
                password: /./,
                city: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                cityMsg: "Please enter a valid city. Allowed special characters are & + / : ' , - . #.",
                street: /([^\s])/,
                address: /([^\s])/,
                streetMsg: 'Please enter street address.',
                grossArea: /^\d*(\.\d+)?$/,
                grossAreaMsg: 'Please enter valid gross area.',
                population: /^0*([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-8][0-9]{4}|9[0-8][0-9]{3}|99[0-8][0-9]{2}|999[0-8][0-9]|9999[0-9]|[1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9]|[1-8][0-9]{8}|9[0-8][0-9]{7}|99[0-8][0-9]{6}|999[0-8][0-9]{5}|9999[0-8][0-9]{4}|99999[0-8][0-9]{3}|999999[0-8][0-9]{2}|9999999[0-8][0-9]|99999999[0-9]|1[0-9]{9}|20[0-9]{8}|21[0-3][0-9]{7}|214[0-6][0-9]{6}|2147[0-3][0-9]{5}|21474[0-7][0-9]{4}|214748[0-2][0-9]{3}|2147483[0-5][0-9]{2}|21474836[0-3][0-9]|214748364[0-7])$/,
                populationMsg: 'Please enter valid population.',
                gross_area: /^\d*(\.\d+)?$/,
                gross_areaMsg: 'Please enter valid gross area.',
                operationalDays: /^\d*$/,
                operationalDaysMsg: 'Please enter valid number of days in a year.',
                occupancy: /^0*([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-8][0-9]{4}|9[0-8][0-9]{3}|99[0-8][0-9]{2}|999[0-8][0-9]|9999[0-9]|[1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9]|[1-8][0-9]{8}|9[0-8][0-9]{7}|99[0-8][0-9]{6}|999[0-8][0-9]{5}|9999[0-8][0-9]{4}|99999[0-8][0-9]{3}|999999[0-8][0-9]{2}|9999999[0-8][0-9]|99999999[0-9]|1[0-9]{9}|20[0-9]{8}|21[0-3][0-9]{7}|214[0-6][0-9]{6}|2147[0-3][0-9]{5}|21474[0-7][0-9]{4}|214748[0-2][0-9]{3}|2147483[0-5][0-9]{2}|21474836[0-3][0-9]|214748364[0-7])$/,
                occupancyMsg: 'Please enter valid population.',
                name: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                owner_name: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                first_name: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                last_name: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityName: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityNameMsg: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityAdd1: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityAdd1Msg: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityAdd2: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityAdd2Msg: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityCity: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityCityMsg: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                year_constructed: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                nameMsg: "Please enter a valid name. Allowed special characters are & + / : ' , - . #",
                owner_nameMsg: "Please enter a valid name. Allowed special characters are & + / : ' , - . #",
                first_nameMsg: "Please enter a valid name. Allowed special characters are & + / : ' , - . #",
                last_nameMsg: "Please enter a valid name. Allowed special characters are & + / : ' , - . #",
                organization: /^[\(\)\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                organization_contact: /^[\(\)\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                organizationMsg: "Allowed special characters are & + / : ' , - . # ( )",
                postal: /([^\s])/,
                postalMsg: 'Please enter a valid zip code.',
                zip_code: /([^\s])/,
                zip_codeMsg: 'Please enter a valid zip code.',
                noOfFloors: /^[1-9]\d*$/,
                noOfFloorsMsg: 'Please enter valid number of floor.',
                noOfParkingSpace: /^[1-9]\d*$/,
                noOfParkingSpaceMsg: 'Please enter valid number of parking space.',
                noOfParkingLevels: /^[1-9]\d*$/,
                noOfParkingLevelsMsg: 'Please enter valid number of parking levels.',
                noOfResUnits: /^[1-9]\d*$/,
                noOfResUnitsMsg: 'Please enter valid number of units.',
                operating_hours: /^0*([1-9]|[1-8][0-9]|9[0-9]|1[0-5][0-9]|16[0-8])$/,
                operating_hoursMsg: 'Please enter valid number of hours',
                operatingHours: /^0*([1-9]|[1-8][0-9]|9[0-9]|1[0-5][0-9]|16[0-8])$/,
                operatingHoursMsg: 'Please enter valid number of hours',
                projectId: /^[1-9]\d*$/,
                projectIdMsg: 'Please enter valid project ID',
                geoLat: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
                geoLatMsg: 'Please enter valid latitude.',
                geoLong: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
                geoLongMsg: 'Please enter valid longitude.',
                CC_expiry: /^\s*(0[1-9]|1[0-2])\/(\d{4})\s*$/,
                CC_expiryMsg: 'Please enter valid expiry date',
                CC_number: /^[1-9]\d*$/,
                CC_numberMsg: 'Please enter valid credit card number.',
                CC_CVV: /^[0-9]\d*$/,
                CC_CVVMsg: 'Please enter valid CVV.',
                email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                organization_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                emailMsg: 'Please enter valid email.',
                owner_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                owner_emailMsg: 'Please enter valid email.',
                state: /^null|$/,
                stateMsg: 'Please select valid state.',
                phone_number: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                projectWebsite: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                phone_numberMsg: 'Please enter a valid phone number.',
                phoneMsg: 'Please enter a valid phone number.',
                projectWebsiteMsg: 'Please enter a valid website.',
                weburl: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                orgWebSiteP: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                orgWebSitePError: 'Please enter valid URL',
                annual_ridership: /^[1-9]\d*$/,
                annual_ridershipMsg: 'Please enter valid annual ridership.',
                annualRiderShip: /^[1-9]\d*$/,
                annualRiderShipMsg: 'Please enter valid annual ridership.',
                full_time_staff: /^[1-9]\d*$/,
                full_time_staffMsg: 'Please enter valid full time staff.',
                time_spent_by_riders: /^[1-9]\d*$/,
                time_spent_by_ridersMsg: 'Please enter valid hours.',
                gstin: /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1})?$/i,
                gstinMsg: 'Enter valid GSTIN',
                invoice: /^\d{10}$/,
                invoiceMsg: 'Please enter valid invoice number',
                portfolio_name: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                portfolio_nameMsg: "Please enter a valid name. Allowed special characters are & + / : ' , - . #",
                IsLeedRegistration: /^(\w+\S+)$/,
                IsLeedRegistration_nameMsg: 'This field is required',
                PrevCertProdId: /^[1-9]\d*$/,
                PrevCertProdIdMsg: 'Please enter valid Project ID.',
                nameOfSchool: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                nameOfSchoolMsg: "Please enter a school name. Allowed special characters are & + / : ' , - . #",
                elec_percent_reduction: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
                elec_percent_reductionMsg: 'Please enter a valid percentage.',
                ng_percent_reduction: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
                ng_percent_reductionMsg: 'Please enter a valid percentage.',
                water_percent_reduction: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
                water_percent_reductionMsg: 'Please enter a valid percentage.',
                water_reclaimed_percent_reduction: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
                water_reclaimed_percent_reductionMsg: 'Please enter a valid percentage.',
                daily_average_renewable_design_reduction: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
                daily_average_renewable_design_reductionMsg: 'Please enter a valid percentage.',
                daily_average_energy_elect_design: /^\d*(\.\d+)?$/,
                daily_average_energy_elect_designMsg: 'Please enter a valid target.',
                daily_average_energy_ng_design: /^\d*(\.\d+)?$/,
                daily_average_energy_ng_designMsg: 'Please enter a valid target.',
                daily_water_potable_design: /^\d*(\.\d+)?$/,
                daily_water_potable_designMsg: 'Please enter a valid target.',
                daily_water_reclaimed_design: /^\d*(\.\d+)?$/,
                daily_water_reclaimed_designMsg: 'Please enter a valid target.',
                daily_average_renewable_design: /^\d*(\.\d+)?$/,
                daily_average_renewable_designMsg: 'Please enter a valid target.',
                project_type: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                project_typeMsg: 'Please select a project type.',
                unitType: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                unitTypeMsg: 'Please select a project type.',
                spaceType: /^[\(\)\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                spaceTypeMsg: 'Please select a project type.',
                ownerType: /^[\(\)\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                ownerTypeMsg: 'Please select a project type.',
                manageEntityCountry: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                manageEntityCountryMsg: 'Please select a project type.',
                country: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                countryMsg: 'Please select a project type.',
                station_type: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                station_typeMsg: 'Please select a project type.',
                organization_country: /^[\#\&\+\/\:\'\,\.\- a-zA-Z0-9]+$/,
                organization_countryMsg: 'Please select a project type.'
            };
        } catch (e) {
            console.log(e, 'REGEX INIT ERROR');
        }
    }

    validate(data: string | number, key: string): boolean {
        return this.regex[key].test(data);
    }

    validateOrganization(org_name: string, org_full_country: string) {
        const data = {
            sdn_type: 'organization',
            organization_name: org_name,
            organization_country: org_full_country
        };

        const validation_path = '/organization/validate/';

        return this.api.post(validation_path, data);
    }

    validateOwner(user_name: string, org_full_country: string) {
        const data = {
            sdn_type: 'person',
            user_name,
            organization_country: org_full_country
        };

        const validation_path = '/organization/validate/';

        return this.api.post(validation_path, data);
    }
}
