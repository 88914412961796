import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { RedirectService } from '@shared/services/redirect.service';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PaymentGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly redirect: RedirectService,
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(
            (data) => {
                const urlParams = new URLSearchParams(window.location.search);
                const next = urlParams.get('next');
                const session_id = urlParams.get('session_id');
                const payment_type = urlParams.get('payment_type');
                const leed_id = urlParams.get('leed_id');

                let url = state.url;

                if (url == '/') {
                    this.redirect.redirectToHub(environment.HUB_URL + '/home');
                } 
                else if(next != null) {
                    let path = '';

                    switch(payment_type)
                    {
                        case 'climate_risk_report':
                            path = 'properties/' + leed_id + '/climate-risk/payment-success';
                            break;

                        case 'arc_performance_certificate':
                            path = 'properties/' + leed_id + '/performance-certificates/payment-success';
                            break;
                        
                        case 'arc_pro':
                            path = 'user/payment-success';
                            break;
    
                        default: 
                            path = 'properties';
                            break;
                    }

                    this.router.navigate([path], { queryParams: {
                        session_id: session_id,
                        payment_type: payment_type
                    } });
                }
            }
        );

        return true;
    }
}
