<div class="container">
    <div class="landing">

        <div class="landing-content">
            <div class="landing-content-heading">
                SCORE MODELING
            </div>
    
            <div class="landing-content-info">
                Use advanced features to improve performance. 
                <br>
                <br>
                Score modeling tools enable users to explore relationships between performance metrics and the Arc Performance Score, This provides insights to improve performance and increase scores.
            </div>
    
            <div class="landing-content-bullets">
                <div>
                    <img src="../../../../assets/icons/svg/badge-check.svg">
                    Analyze Arc Performance Scores across a range of parameters
                </div>
    
                <div>
                    <img src="../../../../assets/icons/svg/badge-check.svg">
                    Calculate scores with changes in measured performance.
                </div>
    
                <div>
                    <img src="../../../../assets/icons/svg/badge-check.svg">
                    Estimate the performance needed to achieve a specific Performance Score.
                </div>
    
                <div>
                    <img src="../../../../assets/icons/svg/badge-check.svg">
                    Inform plans to achieve or improve LEED certification level.
                </div>
            </div>
        </div>
    
        <div class="landing-payment">
            <div class="landing-payment-card">
                <div class="landing-payment-card-heading">
                    <div class="landing-payment-card-heading-logo arc_logo_racetrack arc_logo_racetrack_big"></div>
                    Arc Essentials
                </div>
    
                <div class="landing-payment-card-price">
                    <div class="price">
                        $850
                        <span>per user</span>
                    </div>
                    <div class="price-duration">
                        billed annually
                    </div>
                </div>
    
                <div class="landing-payment-card-action">
                    <button (click)="purchaseSubscription()" color="primary" mat-flat-button>Purchase Subscription</button>
                </div>
    
                <div class="landing-payment-card-bullets">
                    <div>
                        <img src="../../../../assets/icons/svg/badge-check-circle.svg">
                        Analyze markets across the United States.
                    </div>
        
                    <div>
                        <img src="../../../../assets/icons/svg/badge-check-circle.svg">
                        Model and compare new projects.
                    </div>
        
                    <div>
                        <img src="../../../../assets/icons/svg/badge-check-circle.svg">
                        Model Arc Performance Scores for spaces and buildings.
                    </div>
        
                    <div>
                        <img src="../../../../assets/icons/svg/badge-check-circle.svg">
                        Create and customize project performance reports.
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</div>