import { Injectable } from '@angular/core';
import { AppData } from '@shared/legacy-services/appdata.service';
import { GlobalService } from '@shared/legacy-services/global.service';
import { tap } from 'rxjs';
import { Config } from 'src/app/core/config';
import { API } from 'src/app/shared/legacy-services/api.service';

@Injectable({ providedIn: 'root' })
export class PropertyBoostrapper {
    constructor(
        private readonly api: API,
        private readonly appData: AppData,
        private readonly global: GlobalService
    ) {}

    fetchPrice() {
        this.api.get(
            '/price/'
        ).subscribe(data => {
            this.appData.set('currency', '$');
            if (this.appData.get('currency') == '$' || this.appData.get('currency') == undefined) {
                var cr = 'USD';
                Config.currency = '$';
                Config.price.arc_pro_payment_type = 'all';
            } else {
                var cr = 'YEN';
                Config.currency = '¥';
                Config.price.arc_pro_payment_type = 'yearly';
            }

            for (const i in data) {
                if (data[i].soreference == 'premium' && data[i].period == 'monthly' && data[i].currency == cr) {
                    Config.price.arc_pro_monthly = this.global.formatNumber(data[i].price_per_unit, 2);
                } else if (data[i].soreference == 'premium' && data[i].period == 'annually' && data[i].currency == cr) {
                    Config.price.arc_pro_annually = this.global.formatNumber(data[i].price_per_unit, 2);
                } else if (data[i].soreference == 'performance' && data[i].period == 'monthly') {
                    Config.price.certificate_monthly = this.global.formatNumber(data[i].price_per_unit, 2);
                } else if (data[i].soreference == 'performance' && data[i].period == 'annually' && data[i].currency == cr) {
                    Config.price.certificate_annually = this.global.formatNumber(data[i].price, 2);
                } else if (data[i].soreference == 'climaterisk' && data[i].currency == cr) {
                    Config.price.climaterisk_price = data[i].price;
                }
            }
        });
    };

    getProjectTeam(leed_id: number) {
        return this.api.get(
            '/assets/property:' + leed_id + '/teams/'
        ).pipe(
            tap(data => {
                Config.access = 'CRUD';

                for (let i = 0; i < data.EtTeamMembers.length; i++) {
                    if (data.EtTeamMembers[i].Useralias == localStorage.getItem('user_id')?.substring(0, 40)) {
                        if (data.EtTeamMembers[i].Roleid == 'ARC01' || data.EtTeamMembers[i].Roleid == 'ARC02') {
                            Config.access = 'R';
                        }

                        if (data.EtTeamMembers[i].Roleid == 'ZRPO80' || data.EtTeamMembers[i].Roleid == 'ZRPO03' || data.EtTeamMembers[i].Roleid == 'ZRPO81' || data.EtTeamMembers[i].Roleid == 'ZRPO82' || data.EtTeamMembers[i].Roleid == 'ARC01' || data.EtTeamMembers[i].Roleid == 'ARC02') {
                            data.EUserRole = data.EtTeamMembers[i].Roledescription;
                            break;
                        }
                    }
                }

                Config.EUserRole = data.EUserRole;

                if (data.EUserRole == '') {
                    Config.access = 'R';
                }
            })
        );
    }
}
